import { E_EVENT_KEY, E_VIEW_MODE, E_ATTACH_LAYER } from '@/components/editor/ThinkEditor.Defined';
import { barCodeImageBase64Str } from '@/testmaterial/BarCodeImageBase64Str.js';
import { Base64 } from 'js-base64';
export const DemoCaseGenerateHealthReport = {
  methods: {
    async DoDemoCaseGenerateHealthReport() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '动态生成体检报告';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/功能/高级/GenerateHealthReport.xml';
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', 'zl', 1, '');

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnGenerateHealthReportTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let thinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (thinkEditor === undefined) {
        return;
      }
      /**************************************************************************
          填充基本信息
      ************************************************************************/
      await this.FillBaseInfo(thinkEditor);
      /**************************************************************************
          填充提示
      ************************************************************************/
      await this.FillNotice(thinkEditor);
      /**************************************************************************
          填充体检综述
      ************************************************************************/
      await this.Fill体检综述(thinkEditor);
      await this.Fill总检(thinkEditor);
      /**************************************************************************
          填充体检综述
      ************************************************************************/
      await this.Fill医生建议(thinkEditor);
      /**************************************************************************
          填充报告表格
      ************************************************************************/
      await this.FillReport(thinkEditor);

      /**************************************************************************
          切换为 浏览显示或直接打印
      ************************************************************************/
      thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {
        hideElementHint: true
      });
    },
    async FillBaseInfo(thinkEditor) {
      let eleDatas = [];
      /*******************************************设置二维码元素************************************/

      //barCodeImageBase64Str是为了演示而预设的一个测试数据，应用自行动态更新
      // let barcodeImageBase64String = barCodeImageBase64Str; //二维码图片base64编码
      // var md5 = thinkEditor.SetImageResourceBase64('体检条形码.jpg', barcodeImageBase64String, barcodeImageBase64String.length, {});
      // var signFragment = "<Fragment><Image width='2.5' height='1.0' file='体检条形码.jpg' md5='" + md5 + "'/></Fragment>";
      // eleDatas.push({ id: 'barcode', content: signFragment });

      let dataBase64Str = Base64.encode('666');
      thinkEditor.SetBarCodeConfig('barcode', { data: dataBase64Str });
      /*******************************************************************************/
      eleDatas.push({ id: 'name', content: '张三' });
      eleDatas.push({ id: 'age', content: '36' });

      eleDatas.push({ id: 'yes', content: '○' });
      eleDatas.push({ id: 'no', content: '●' });
      thinkEditor.SetElementsContent(eleDatas);
    },
    async FillNotice(thinkEditor) {
      let eleDatas = [];

      eleDatas.push({ id: 'userName', content: '尊敬的张XX庄先生 ' });

      thinkEditor.SetElementsContent(eleDatas);
    },
    async Fill体检综述(thinkEditor) {
      //--依次填充段落
      let contentXml = '<Paragraph>【一般检查】</Paragraph>';
      contentXml += '<Paragraph><Space count="4"/>体格检查：收缩压：160 ↑；舒张压：100 ↑</Paragraph>';
      contentXml += '<Paragraph>【超声科】</Paragraph>';
      contentXml += '<Paragraph><Space count="4"/>前列腺:前列腺未见明显异常。</Paragraph>';
      contentXml += '<Paragraph><Space count="4"/>肝胆脾胰肾:肝内钙化灶。</Paragraph>';
      contentXml += '<Paragraph><Space count="4"/>肝囊肿。</Paragraph>';
      thinkEditor.SetCellContent({ tableId: 'overview', cellId: 'tjzs', content: contentXml });
    },
    async Fill总检(thinkEditor) {
      //--依次填充段落
      let contentXml = "<Paragraph xCfg='100' specificIndentValue='2.000'>	<Font cfg='1' />体检结果提示:<Font cfg='0' /></Paragraph>";
      contentXml += '<Paragraph>1、轻度动脉硬化</Paragraph>';
      contentXml += '<Paragraph>2、25-羟基维生素D3缺乏</Paragraph>';
      contentXml += '<Paragraph>3、血浆肾素活性偏低</Paragraph>';
      contentXml += '<Paragraph>4、尿酸(UA)偏高</Paragraph>';
      contentXml += '<Paragraph>5、收缩压高血压</Paragraph>';
      contentXml += '<Paragraph>6、舒张压高血压</Paragraph>';
      contentXml += '<Paragraph>7、肝内钙化灶。肝囊肿。</Paragraph>';
      thinkEditor.SetCellContent({ tableId: 'overview', cellId: 'zj', content: contentXml });
    },
    async Fill医生建议(thinkEditor) {
      let contentXml = `<Paragraph>1、[舒张压高血压]</Paragraph>
				<Paragraph>建议您密切关注血压，每周测1-2次，劳逸结合，保持良好身心状态。低盐饮食，到内科</Paragraph>
				<Paragraph>进一步诊断治疗。</Paragraph>
				<Paragraph>2、[收缩压高血压]</Paragraph>
				<Paragraph>建议您到内科进一步诊断治疗。</Paragraph>
				<Paragraph>3、[25-羟基维生素D3缺乏]</Paragraph>
				<Paragraph xCfg='100' specificIndentValue='2.000'>1.口服补充:首选口服维生素D制剂，</Paragraph>
				<Paragraph>2.饮食调理:增加富含维生素D的食物摄入，如鱼肝油、蛋黄、动物肝脏、香菇、三文鱼类等。</Paragraph>
				<Paragraph>3.适当晒太阳有助于皮肤合成维生素D。建议每天保证一定的日晒时间，但应避免阳光直射和过度暴晒。</Paragraph>
				<Paragraph>4.合理休息:通过合理休息，可以刺激机体的代谢，有助于维生素D的吸收和利用。<SelectionBegin side='1' />
					<SelectionEnd side='1' />
				</Paragraph>`;
      thinkEditor.SetCellContent({ tableId: 'doctorAdvice', cellId: 'ysjy', content: contentXml });

      let eleDatas = [];
      /***********************************初审医生签名****************************************/
      var url = '/system/test/sign/sign.png';
      let response = await fetch(url, { credentials: 'same-origin' });
      let signImgData = await response.arrayBuffer();
      //使用SetImageResource或SetImageResourceBase64加载图像数据
      var md5 = this.thinkEditor.SetImageResource('当前医师电子签名.jpg', signImgData, signImgData.byteLength, {});
      var signFragment = "<Fragment><Image width='2' height='0.8' file='当前医师电子签名.jpg' md5='" + md5 + "'/></Fragment>";

      eleDatas.push({ id: 'firstReviewDoctor', content: signFragment });
      eleDatas.push({ id: 'firstReviewTime', content: '2024-11-21' });
      /*******************************************************************************/
      /***********************************总检医生签名****************************************/
      url = '/system/test/sign/signChief.png';
      response = await fetch(url, { credentials: 'same-origin' });
      let signChiefImgData = await response.arrayBuffer();
      //使用SetImageResource或SetImageResourceBase64加载图像数据
      md5 = this.thinkEditor.SetImageResource('上级医师电子签名.jpg', signChiefImgData, signChiefImgData.byteLength, {});
      signFragment = "<Fragment><Image width='2' height='0.8' file='审核医师电子签名.jpg' md5='" + md5 + "'/></Fragment>";

      eleDatas.push({ id: 'finallyReviewDoctor', content: signFragment });
      eleDatas.push({ id: 'finallyReviewTime', content: '2024-11-21' });
      /*******************************************************************************/
      thinkEditor.SetElementsContent(eleDatas);
    },

    FillReportTitleRow1(title, doctorName, checkTime) {
      let rowHeight = 0.8;
      let rowXml = `<Row height='${rowHeight}'>`;
      rowXml += `<Cell width='5.7' ><Paragraph><Font cfg='1' />${title}</Paragraph></Cell>`; //第1个单元格
      rowXml += `<Cell width='4.0' ><Paragraph><Font cfg='1' />检查医生：<Font cfg='0' />${doctorName}</Paragraph></Cell>`; //第2个单元格
      rowXml += `<Cell width='5.0' colSpan='3'><Paragraph><Font cfg='1' />小结日期：<Font cfg='0' />${checkTime}</Paragraph></Cell>`; //第2个单元格
      rowXml += '</Row>';
      return rowXml;
    },
    FillReportTitleRow2() {
      let rowHeight = 0.8;
      let rowXml = `<Row height='${rowHeight}'>`;
      rowXml += "<Cell width='14.7' colSpan='5'>";
      rowXml += "<Paragraph><Separator height='0.049' lineWidth='0.040' lCfg='5' />";
      rowXml += "<Font cfg='1' backColor='00ff00' />项目名称<Space count='16'/>检查结果<Space count='11'/>单位<Space count='5' />参考范围<Space count='5'/>提示";
      rowXml += "<Separator height='0.049' lineWidth='0.040' lCfg='5' /></Paragraph>";
      rowXml += '</Cell>';
      rowXml += '</Row>';
      return rowXml;
    },
    FillReportTitleRow3(title) {
      let rowHeight = 0.8;
      let rowXml = `<Row height='${rowHeight}'>`;
      rowXml += "<Cell width='14.7' colSpan='5'>";
      rowXml += `<Paragraph><Font cfg='1' />${title}</Paragraph>`;
      rowXml += '</Cell>';
      rowXml += '</Row>';
      return rowXml;
    },
    FillReportDataRow(item, height, unit, range, notice) {
      //total width 14.7cm
      let itemWidth1 = 5.2;
      let itemWidth2 = 4.2;
      let itemWidth3 = 2;
      let itemWidth4 = 2.3;
      let itemWidth5 = 1;
      let rowHeight = 0.8;
      let rowXml = `<Row height='${rowHeight}'>`;
      rowXml += `<Cell width='${itemWidth1}'><Paragraph>${item}</Paragraph></Cell>`;
      rowXml += `<Cell width='${itemWidth2}'><Paragraph>${height}</Paragraph></Cell>`; //检查结果
      rowXml += `<Cell width='${itemWidth3}'><Paragraph>${unit}</Paragraph></Cell>`; //单位
      rowXml += `<Cell width='${itemWidth4}'><Paragraph>${range}</Paragraph></Cell>`; //范围
      rowXml += `<Cell width='${itemWidth5}'><Paragraph>${notice}</Paragraph></Cell>`; //提示
      rowXml += '</Row>';
      return rowXml;
    },
    FillReport小结(title, info) {
      //total width 14.7cm

      let rowHeight = 0.8;
      let rowXml = `<Row height='${rowHeight}'>`;
      rowXml += "<Cell width='14.7' colSpan='5'>";
      rowXml += `<Paragraph><Font cfg='1' />${title}<Font cfg='0' />${info}</Paragraph>`;
      rowXml += '</Cell>';
      rowXml += '</Row>';

      return rowXml;
    },
    FillReport(thinkEditor) {
      /************************************************
       * 首先确定表格需要的最多列，示例为：5列。制作模版时 添加一个5列表格，Id设置为'report'
       * 页面设置：A4纸 内容可显示宽度为  21-3.14-3.14= 14.71cm
       * ************************************************/
      let tableXml = '<Table>';
      //--------------------------------
      tableXml += this.FillReportTitleRow1('一般检查', '刘群安', '2024-11-21');
      tableXml += this.FillReportTitleRow2();
      /****************************************************************
       * 一般检查
       * ****************************************************************/
      tableXml += this.FillReportTitleRow3('一般检查');

      //具体报告参数
      tableXml += this.FillReportDataRow('身高', '180', 'cm', '-', ''); //把一些重复工作封装起来
      tableXml += this.FillReportDataRow('体重', '74.5', 'kg', '-', '');
      tableXml += this.FillReportDataRow('收缩压', '160', 'mmHg', '90-139', "<Font color='ff0000'/>↑"); //注意：字体红字
      tableXml += this.FillReportDataRow('舒张压', '100', 'mmHg', '60-89', "<Font color='ff0000'/>↑"); //注意：字体红字

      tableXml += this.FillReport小结('小结：', '体格检查：收缩压：160 ↑；舒张压：100 ↑');

      tableXml += this.FillReportTitleRow1('化验科', '张超', '2024-11-21');
      tableXml += this.FillReportTitleRow2();
      /****************************************************************
       * 高血压四项
       * ****************************************************************/
      tableXml += this.FillReportTitleRow3('高血压四项');
      //具体报告参数
      tableXml += this.FillReportDataRow('醛固酮测定', '146.87', 'pg/mL', '31.0-351.0', ''); //把一些重复工作封装起来
      tableXml += this.FillReportDataRow('血浆肾素活性测定', '0.9', 'pg/mL', '2.8-28.5', '');
      tableXml += this.FillReportDataRow('血浆皮质醇测定', '20.06', 'ug/dl', '6.4-22.8', "<Font color='ff0000'/>↑"); //注意：字体红字
      tableXml += this.FillReportDataRow('血清促肾上腺皮质激素测定', '36.67', 'pg/mL', '7.0-65.0', "<Font color='ff0000'/>↑"); //注意：字体红字

      tableXml += this.FillReportTitleRow3('25-羟基维生素D3测定');
      //具体报告参数
      tableXml += this.FillReportDataRow('醛固酮测定', '146.87', 'pg/mL', '31.0-351.0', ''); //把一些重复工作封装起来
      tableXml += this.FillReportDataRow('血浆肾素活性测定', '0.9', 'pg/mL', '2.8-28.5', '');
      tableXml += this.FillReportDataRow('血浆皮质醇测定', '20.06', 'ug/dl', '6.4-22.8', "<Font color='ff0000'/>↑"); //注意：字体红字
      tableXml += this.FillReportDataRow('血清促肾上腺皮质激素测定', '36.67', 'pg/mL', '7.0-65.0', "<Font color='ff0000'/>↑"); //注意：字体红字

      tableXml += this.FillReport小结('小结：', '血浆肾素活性测定:0.9 ↓;25-羟基维生素D3测定:18.64↓');

      tableXml += '</Table>';

      thinkEditor.SetTableData('report', tableXml, {});
    }
  }
};
