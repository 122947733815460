var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { GetRuntimeVersion, CreateInstance, FlushFrameData } from "./ThinkEditor.Runtime.js";
import { shareDataManager, fontsManager, optionsManager, algorithmsManager, editorLogger, SourcesManager, ThinkEditorEvent, ThinkEditorEventData, E_EVENT_KEY, E_EVENT_HANDLE, E_EVENT_TYPE, E_DOC_FORMAT, E_PASTE_TYPE, } from "./ThinkEditor.Defined.js";
import { Sleep, IsHavingAdvancedPermissions, Validator, NumValidator, IntValidator, StrValidator, NotEmptyStrValidator, ColorValidator, InsertSeparatorCfgValidator, SetFontConfigCfgValidator, GetPrintIframe, GetRandStr, } from "./ThinkEditor.Utils.js";
export class Timestamp {
    constructor() {
        this.createTime = new Date();
    }
    GetTimeLog() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        let initCostTime;
        if (this.beginInitTime !== undefined && this.endInitTime !== undefined) {
            initCostTime = this.endInitTime.getTime() - this.beginInitTime.getTime();
        }
        let parseCostTime;
        if (this.beginParseTime !== undefined && this.endParseTime !== undefined) {
            parseCostTime = this.endParseTime.getTime() - this.beginParseTime.getTime();
        }
        let downloadFontCostTime;
        if (this.beginDownloadFontTime !== undefined && this.endDownloadFontTime !== undefined) {
            downloadFontCostTime = this.endDownloadFontTime.getTime() - this.beginDownloadFontTime.getTime();
        }
        let loadFontCostTime;
        if (this.beginRequestFontTime !== undefined && this.endRequestFontTime !== undefined) {
            loadFontCostTime = this.endRequestFontTime.getTime() - this.beginRequestFontTime.getTime();
            loadFontCostTime = loadFontCostTime - (downloadFontCostTime == undefined ? 0 : downloadFontCostTime);
        }
        let parseFontCostTime;
        if (this.beginParseFontTime !== undefined && this.endParseFontTime !== undefined) {
            parseFontCostTime = this.endParseFontTime.getTime() - this.beginParseFontTime.getTime();
        }
        let produceLinesCostTime;
        if (this.beginProduceLinesTime !== undefined && this.endProduceLinesTime !== undefined) {
            produceLinesCostTime = this.endProduceLinesTime.getTime() - this.beginProduceLinesTime.getTime();
        }
        let producePagesCostTime;
        if (this.beginProducePagesTime !== undefined && this.endProducePagesTime !== undefined) {
            producePagesCostTime = this.endProducePagesTime.getTime() - this.beginProducePagesTime.getTime();
        }
        let totalCostTime;
        if (this.fristDrawTime !== undefined) {
            totalCostTime = this.fristDrawTime.getTime() - this.createTime.getTime();
        }
        return `CreateTime:${this.createTime.toISOString()} 
    BeginInitTime:${(_a = this.beginInitTime) === null || _a === void 0 ? void 0 : _a.toISOString()} EndInitTime:${(_b = this.endInitTime) === null || _b === void 0 ? void 0 : _b.toISOString()} 
    BeginParseTime:${(_c = this.beginParseTime) === null || _c === void 0 ? void 0 : _c.toISOString()} EndParseTime:${(_d = this.endParseTime) === null || _d === void 0 ? void 0 : _d.toISOString()} 
    BeginRequestFontTime:${(_e = this.beginRequestFontTime) === null || _e === void 0 ? void 0 : _e.toISOString()} EndRequestFontTime:${(_f = this.endRequestFontTime) === null || _f === void 0 ? void 0 : _f.toISOString()} 
    BeginDownloadFontTime:${(_g = this.beginDownloadFontTime) === null || _g === void 0 ? void 0 : _g.toISOString()} EndDownloadFontTime:${(_h = this.endDownloadFontTime) === null || _h === void 0 ? void 0 : _h.toISOString()} 
    BeginParseFontTime:${(_j = this.beginParseFontTime) === null || _j === void 0 ? void 0 : _j.toISOString()} EndParseFontTime:${(_k = this.endParseFontTime) === null || _k === void 0 ? void 0 : _k.toISOString()} 
    BeginProduceLinesTime:${(_l = this.beginProduceLinesTime) === null || _l === void 0 ? void 0 : _l.toISOString()} EndProduceLinesTime:${(_m = this.endProduceLinesTime) === null || _m === void 0 ? void 0 : _m.toISOString()} 
    BeginProducePagesTime:${(_o = this.beginProducePagesTime) === null || _o === void 0 ? void 0 : _o.toISOString()} EndProducePagesTime:${(_p = this.endProducePagesTime) === null || _p === void 0 ? void 0 : _p.toISOString()} 
    FristDrawTime:${(_q = this.fristDrawTime) === null || _q === void 0 ? void 0 : _q.toISOString()} 
    初始化耗时：${(_r = this.beginInitTime) === null || _r === void 0 ? void 0 : _r.toISOString()} Init:${initCostTime}ms 解析:${parseCostTime}ms 下载字体:${downloadFontCostTime}ms 加载字体：${loadFontCostTime}ms 解析字体：${parseFontCostTime}ms 分行:${produceLinesCostTime}ms 布局页:${producePagesCostTime}ms 总耗时:${totalCostTime}ms`;
    }
}
export class ThinkEditor extends EventTarget {
    constructor(param) {
        var _a;
        super();
        this.docName = "";
        this.showName = "";
        this.selected = false;
        this.selectedTime = new Date();
        this.isLoaded = false;
        this.timestamp = new Timestamp();
        this.sourcesManager = new SourcesManager();
        this.errorCount = 0;
        this.inputText = "";
        this.lastInputTime = new Date().getTime();
        this._OnDocumentKeyDown = this.OnDocumentKeyDown.bind(this);
        this._OnDocumentKeyUp = this.OnDocumentKeyUp.bind(this);
        this._OnDocumentMouseDown = this.OnDocumentMouseDown.bind(this);
        this._OnDocumentMouseUp = this.OnDocumentMouseUp.bind(this);
        this._OnDocumentMouseMove = this.OnDocumentMouseMove.bind(this);
        this._OnDocumentPaste = this.OnDocumentPaste.bind(this);
        this._OnDocumentCopy = this.OnDocumentCopy.bind(this);
        this._OnDocumentCut = this.OnDocumentCut.bind(this);
        this._OnDocumentPasteV2 = this.OnDocumentPasteV2.bind(this);
        this._OnDocumentCopyV2 = this.OnDocumentCopyV2.bind(this);
        this._OnDocumentCutV2 = this.OnDocumentCutV2.bind(this);
        this._OnDocumentClick = this.OnDocumentClick.bind(this);
        this._param = param;
        this.docName = param.docName;
        this.showName = (_a = param.showName) !== null && _a !== void 0 ? _a : this.docName;
        this.forbidCallAutoSetInputFocus = param.forbidCallAutoSetInputFocus;
        editorLogger.InitLogLevel(param.logLevel);
        fontsManager.forbidLocalFonts = param.forbidLocalFonts;
        fontsManager.debugFont = param.debugFont;
    }
    get param() {
        return this._param;
    }
    get lib() {
        let lib = this._param.lib;
        if (lib !== undefined) {
            return lib;
        }
        return "/static/editor/";
    }
    get fontPath() {
        let fontPath = this._param.fontPath;
        if (fontPath !== undefined) {
            return fontPath;
        }
        return "/static/system/user_font/";
    }
    get editorId() {
        let editorId = this._param.editorId;
        if (editorId !== undefined) {
            return editorId;
        }
        this._param.editorId = this.docName + "_" + GetRandStr();
        return this._param.editorId;
    }
    async InitAsync() {
        editorLogger.LogDebug(`Init editorId: ${this.editorId} ${new Date()}`);
        let needInitInternal = false;
        if (this.instance === undefined) {
            needInitInternal = true;
        }
        else {
            this.instance = undefined;
        }
        this.timestamp.beginInitTime = new Date();
        this.instance = await CreateInstance(this.lib);
        this.instance.OnDispatchInnerEvent = (eventType, data) => {
            editorLogger.LogEvent(new Date(), " OnDispatchInnerEvent==>", data);
            if (data.data === undefined) {
                console.warn("编辑器内部事件:", eventType, " 缺失data");
            }
            let eventData = data;
            eventData.editor = this;
            return this.dispatchEvent(new ThinkEditorEvent(eventType, eventData));
        };
        this.InitInner();
        if (needInitInternal) {
            this.InitInternal();
        }
        this.timestamp.endInitTime = new Date();
        return true;
    }
    InitInternal() {
        this.InitCanvas();
        this.InitInput();
        this.InitEditorInnerEventListener();
        this.InitEditorOuterEventListener();
        this.InitCanvasOuterEventListener();
        this.InitDocumentOuterEventListener();
    }
    PrintVersion() {
        let shellVersion = "2025-03-27 23:19";
        let runtimeVersion = GetRuntimeVersion();
        let editorProperties = this.GetEditorProperties();
        let coreVersion = editorProperties === null || editorProperties === void 0 ? void 0 : editorProperties.version;
        console.warn(`Version complied_time Core:[${coreVersion}] Shell:[${shellVersion}] Runtime:[${runtimeVersion}]`);
    }
    async Init() {
        editorLogger.LogDebug(`Init editorId: ${this.editorId} ${new Date()}`);
        if (this.instance !== undefined) {
            console.warn(`无需重复初始化 Editor: ${this.editorId}`);
            return false;
        }
        this.timestamp.beginInitTime = new Date();
        this.instance = await CreateInstance(this.lib);
        this.InitInner();
        this.InitCanvas();
        this.InitInput();
        this.InitEditorInnerEventListener();
        this.InitEditorOuterEventListener();
        this.InitCanvasOuterEventListener();
        this.InitDocumentOuterEventListener();
        this.instance.OnDispatchInnerEvent = (eventType, data) => {
            editorLogger.LogEvent(new Date(), " OnDispatchInnerEvent==>", data);
            if (data.data === undefined) {
                console.warn("编辑器内部事件:", eventType, " 缺失data");
            }
            let eventData = data;
            eventData.editor = this;
            return this.dispatchEvent(new ThinkEditorEvent(eventType, eventData));
        };
        this.timestamp.endInitTime = new Date();
        return true;
    }
    UnInit() {
        editorLogger.LogDebug(`UnInit editorId: ${this.editorId} ${new Date()}`);
        this.UnInitDocumentOuterEventListener();
        this.UnInitCanvasOuterEventListener();
        this.UnInitCanvas();
        this.UnInitInput();
        this.instance = undefined;
    }
    UnInitCanvas() {
        if (this.canvas === undefined || this.container === undefined) {
            return;
        }
        if (this.canvas.parentNode === this.container) {
            this.container.removeChild(this.canvas);
        }
        this.canvas = undefined;
        this.context = undefined;
    }
    UnInitInput() {
        if (this.input === undefined || this.container === undefined) {
            return;
        }
        this.UnInitEditorOuterEventListener();
        this.container.removeChild(this.input);
        this.input = undefined;
    }
    Load(container) {
        if (this.canvas === undefined || this.input === undefined) {
            console.error("无法加载：当前编辑器未初始化!");
            return;
        }
        if (container === undefined) {
            console.warn("无法加载：需等待ThinkEitorView挂载完成!");
            return;
        }
        editorLogger.LogDebug(`Load editorId: ${this.editorId} ${new Date()}`);
        this.container = container;
        this.isLoaded = true;
        this.container.appendChild(this.canvas);
        this.container.appendChild(this.input);
        this.GetFrameData();
    }
    UnLoad() {
        if (this.container === undefined) {
            return;
        }
        if (!this.isLoaded) {
            return;
        }
        if (this.canvas !== undefined) {
            this.container.removeChild(this.canvas);
        }
        editorLogger.LogDebug(`UnLoad editorId: ${this.editorId} ${new Date()}`);
        this.isLoaded = false;
    }
    InitCanvas() {
        if (this.canvas !== undefined) {
            return;
        }
        let canvas = document.createElement("canvas");
        this.canvas = canvas;
        this.canvas.style.width = "100%";
        this.canvas.style.height = "100%";
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        this.context = this.canvas.getContext("2d");
    }
    InitCanvasOuterEventListener() {
        if (this.canvas === undefined) {
            return;
        }
        let canvas = this.canvas;
        canvas.addEventListener("mousemove", (e) => this.OnCanvasMouseMove(e));
        canvas.addEventListener("mouseout", (e) => this.OnCanvasMouseOut(e));
        canvas.addEventListener("mouseover", (e) => this.OnCanvasMouseOver(e));
        canvas.addEventListener("mousedown", (e) => this.OnCanvasMouseDown(e), true);
        canvas.addEventListener("mouseup", (e) => this.OnCanvasMouseUp(e), true);
        canvas.addEventListener("wheel", (e) => this.OnCanvasMouseWheel(e));
        canvas.addEventListener("touchstart", (e) => this.OnCanvasTouchStart(e), true);
        canvas.addEventListener("dragover", (e) => this.OnCanvasDragoverEvent(e));
        canvas.addEventListener("drop", (e) => this.OnCanvasDropEvent(e));
        canvas.oncontextmenu = function (e) {
            return false;
        };
    }
    UnInitCanvasOuterEventListener() {
        if (this.canvas === undefined) {
            return;
        }
        let canvas = this.canvas;
        canvas.removeEventListener("mouseout", (e) => this.OnCanvasMouseOut(e));
        canvas.removeEventListener("mouseover", (e) => this.OnCanvasMouseOver(e));
        canvas.removeEventListener("click", (e) => this.OnCanvasMouseClick(e));
        canvas.removeEventListener("mousedown", (e) => this.OnCanvasMouseDown(e), true);
        canvas.removeEventListener("mouseup", (e) => this.OnCanvasMouseUp(e), true);
        canvas.removeEventListener("wheel", (e) => this.OnCanvasMouseWheel(e));
        canvas.removeEventListener("touchstart", (e) => this.OnCanvasTouchStart(e));
        canvas.removeEventListener("dragover", (e) => this.OnCanvasDragoverEvent(e));
        canvas.removeEventListener("drop", (e) => this.OnCanvasDropEvent(e));
    }
    InitDocumentOuterEventListener() {
        document.addEventListener("keydown", this._OnDocumentKeyDown);
        document.addEventListener("keyup", this._OnDocumentKeyUp);
        document.addEventListener("mousedown", this._OnDocumentMouseDown, true);
        document.addEventListener("mouseup", this._OnDocumentMouseUp, true);
        document.addEventListener("mousemove", this._OnDocumentMouseMove);
        if (IsHavingAdvancedPermissions()) {
            document.addEventListener("paste", this._OnDocumentPasteV2);
            document.addEventListener("copy", this._OnDocumentCopyV2);
            document.addEventListener("cut", this._OnDocumentCutV2);
        }
        else {
            console.warn("浏览器安全限制,无法通过粘贴paste按钮(接口)访问剪贴版，请使用https!");
            document.addEventListener("paste", this._OnDocumentPaste);
            document.addEventListener("copy", this._OnDocumentCopy);
            document.addEventListener("cut", this._OnDocumentCut);
        }
    }
    UnInitDocumentOuterEventListener() {
        document.removeEventListener("keydown", this._OnDocumentKeyDown);
        document.removeEventListener("keyup", this._OnDocumentKeyUp);
        document.removeEventListener("mousedown", this._OnDocumentMouseDown, true);
        document.removeEventListener("mouseup", this._OnDocumentMouseUp, true);
        document.removeEventListener("mousemove", this._OnDocumentMouseMove);
        if (IsHavingAdvancedPermissions()) {
            document.removeEventListener("paste", this._OnDocumentPasteV2);
            document.removeEventListener("copy", this._OnDocumentCopyV2);
            document.removeEventListener("cut", this._OnDocumentCutV2);
        }
        else {
            document.removeEventListener("paste", this._OnDocumentPaste);
            document.removeEventListener("copy", this._OnDocumentCopy);
            document.removeEventListener("cut", this._OnDocumentCut);
        }
        document.removeEventListener("click", this._OnDocumentClick);
    }
    InitInput() {
        if (this.input !== undefined) {
            return;
        }
        let inputHandle = document.createElement("input");
        this.input = inputHandle;
        let canvas = this.canvas;
        inputHandle.style.width = "5px";
        inputHandle.style.top = canvas.offsetLeft + "px";
        inputHandle.style.left = canvas.offsetTop + "px";
        inputHandle.style.border = "0";
        inputHandle.style.position = "absolute";
        inputHandle.style.zIndex = "-999";
        inputHandle.style.color = "transparent";
        inputHandle.style.outline = "none";
    }
    InitEditorOuterEventListener() {
        if (this.input === undefined) {
            return;
        }
        let inputHandle = this.input;
        inputHandle.addEventListener("focus", () => this.OnInputFocus());
        inputHandle.addEventListener("blur", () => this.OnInputBlur());
        inputHandle.addEventListener("compositionstart", (e) => this.OnInputCompositionStart(e));
        inputHandle.addEventListener("compositionend", (e) => this.OnInputCompositionEnd(e));
        inputHandle.addEventListener("input", (e) => this.OnInputInput(e));
        inputHandle.addEventListener("keydown", (e) => this.OnInputKeyDown(e));
        inputHandle.addEventListener("keyup", (e) => this.OnInputKeyUp(e));
    }
    UnInitEditorOuterEventListener() {
        if (this.input === undefined) {
            return;
        }
        let inputHandle = this.input;
        inputHandle.removeEventListener("focus", () => this.OnInputFocus());
        inputHandle.removeEventListener("blur", () => this.OnInputBlur());
        inputHandle.removeEventListener("compositionstart", (e) => this.OnInputCompositionStart(e));
        inputHandle.removeEventListener("compositionend", (e) => this.OnInputCompositionEnd(e));
        inputHandle.removeEventListener("input", (e) => this.OnInputInput(e));
        inputHandle.removeEventListener("keydown", (e) => this.OnInputKeyDown(e));
        inputHandle.removeEventListener("keyup", (e) => this.OnInputKeyUp(e));
    }
    GetFrameData() {
        let that = this;
        let GetFrameBuffer = () => {
            if (that.isLoaded == false || that.instance === undefined || that.container === undefined || that.canvas === undefined || that.context === undefined) {
                return;
            }
            try {
                let currentTime = new Date().getTime();
                if (this.inputText != "" && currentTime - this.lastInputTime > 100) {
                    this.InputText(this.inputText);
                    this.inputText = "";
                    this.lastInputTime = currentTime;
                }
                FlushFrameData(that);
            }
            catch (e) {
                that.errorCount++;
                console.error(e);
            }
            if (that.errorCount > 10) {
                console.error("严重异常：编辑器停止工作");
                return;
            }
            requestAnimationFrame(GetFrameBuffer);
        };
        GetFrameBuffer();
    }
    GetCurrentEditorSize() {
        return this.instance.ccall("GetCurrentEditorSize", "object", ["null"], ["null"]);
    }
    InitEditorInnerEventListener() {
        this.addEventListener(E_EVENT_KEY.requestFont, this.OnRequestFont);
        this.addEventListener(E_EVENT_KEY.fontsName, this.OnFontsName);
        this.addEventListener(E_EVENT_KEY.setOptions, this.OnSetOptions);
        this.addEventListener(E_EVENT_KEY.delOptions, this.OnDelOptions);
        this.addEventListener(E_EVENT_KEY.setSources, this.OnSetSources);
        this.addEventListener(E_EVENT_KEY.setAlgorithms, this.OnSetAlgorithms);
        this.addEventListener(E_EVENT_KEY.textEditFocused, this.OnTextEditFocused);
        this.addEventListener(E_EVENT_KEY.setCursorStyle, this.OnSetCursorStyle);
        this.addEventListener(E_EVENT_KEY.timeStamp, this.OnTimeStamp);
        this.addEventListener(E_EVENT_KEY.setSearchHistory, this.OnSetSearchHistory);
        this.addEventListener(E_EVENT_KEY.setReplaceHistory, this.OnSetReplaceHistory);
        this.addEventListener(E_EVENT_KEY.setSpecialSymbolHistory, this.OnSetSpecialSymbolHistory);
        this.addEventListener(E_EVENT_KEY.printDoc, this.OnPrintDoc);
        this.addEventListener("executeElementScript", this.OnExecuteElementScript);
    }
    InitInner() {
        this.instance.ccall("InitInner", "null", ["object"], [this._param]);
        this.SetEditorConfig(this._param);
        this.PrintVersion();
    }
    OnDispatchOuterEvent(eventKey, e) {
        delete e.editor;
        delete e.instance;
        return this.instance.ccall("OnDispatchOuterEvent", "bool", ["string", "object"], [eventKey, e]);
    }
    OnInputFocus() {
        this.isInputFocus = true;
        editorLogger.LogDebug(`编辑器[已获得]输入焦点`);
    }
    OnInputBlur() {
        this.isInputFocus = false;
        editorLogger.LogDebug(`编辑器[已失去]输入焦点`);
    }
    OnInputCompositionStart(e) {
        editorLogger.LogDebug(`Inputer on compositionstart`);
        e.target.compositionStart = true;
    }
    OnInputCompositionEnd(e) {
        var isFirefox = false;
        if (navigator.userAgent.indexOf("Firefox") > 0) {
            isFirefox = true;
        }
        editorLogger.LogDebug(`Inputer on  compositionend isFirefox:`, isFirefox);
        if (e.target.compositionStart && !isFirefox) {
            editorLogger.LogDebug(`Inputer OnInputCompositionEnd InputText:[${e.data}]`);
            this.InputText(e.data);
        }
        e.target.compositionStart = false;
    }
    OnInputInput(e) {
        let ev = e;
        editorLogger.LogDebug(`Inputer IME input:[${ev.data}]`);
        if (!e.target.compositionStart) {
            if (ev.data != null) {
                editorLogger.LogDebug(`Inputer OnInputInput InputText:[${ev.data}]`);
                this.inputText += ev.data;
            }
        }
    }
    OnInputKeyDown(e) {
        let data = {
            code: e.code,
            keyCode: e.keyCode,
        };
        if (e.ctrlKey == true && e.code == "KeyF") {
            e.preventDefault();
        }
        this.OnDispatchOuterEvent(E_EVENT_TYPE.keyDown, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.keyDown, data));
    }
    OnInputKeyUp(e) {
        let data = {
            code: e.code,
            keyCode: e.keyCode,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.keyUp, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.keyUp, data));
    }
    OnCanvasTouchStart(e) {
        this.OnDispatchOuterEvent(E_EVENT_TYPE.touchStart, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.touchStart, {}));
        e.preventDefault();
    }
    OnCanvasMouseMove(e) {
        let data = {
            posX: e.offsetX,
            posY: e.offsetY,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseMove, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseMove, data));
        e.preventDefault();
    }
    OnCanvasMouseOut(e) {
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseOut, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseOut, {}));
    }
    OnCanvasMouseOver(e) {
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseOver, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseOver, {}));
    }
    OnCanvasMouseClick(e) {
        editorLogger.LogDebug("OnCanvasMouseClick");
        this.SetInputFocus(true);
    }
    OnCanvasMouseDown(e) {
        editorLogger.LogEvent(new Date(), "OnCanvasMouseDown");
        this.SetInputFocus(true);
        e.preventDefault();
        let data = {
            posX: e.offsetX,
            posY: e.offsetY,
            button: e.button,
            ctrlKey: e.ctrlKey,
            shiftKey: e.shiftKey,
            altKey: e.altKey,
            metaKey: e.metaKey,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseDown, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseDown, data));
    }
    OnCanvasMouseUp(e) {
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseUp, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseUp, {}));
    }
    OnCanvasMouseWheel(e) {
        let data = {
            deltaX: e.deltaX,
            deltaY: e.deltaY,
            deltaZ: e.deltaZ,
            deltaMode: e.deltaMode,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseWheel, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseWheel, data));
        e.preventDefault();
    }
    OnCanvasDragoverEvent(e) {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        let data = {
            isDraggerEffect: true,
            posX: e.offsetX,
            posY: e.offsetY,
            button: e.button,
            ctrlKey: e.ctrlKey,
            shiftKey: e.shiftKey,
            altKey: e.altKey,
            metaKey: e.metaKey,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.mouseDown, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.mouseDown, data));
    }
    OnCanvasDropEvent(e) {
        var dataStr = e.dataTransfer.getData("data");
        editorLogger.LogEvent("OnCanvasDropEvent:" + dataStr);
        var data = JSON.parse(dataStr);
        var type = "drop";
        this.dispatchEvent(new ThinkEditorEvent(type, new ThinkEditorEventData(E_EVENT_HANDLE.event, type, data, this)));
    }
    async OnDocumentKeyDown(e) {
        let data = {
            code: e.code,
            keyCode: e.keyCode,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.documentKeyDown, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.documentKeyDown, data));
        if (e.code == "Tab") {
            e.preventDefault();
        }
    }
    OnDocumentKeyUp(e) {
        let data = {
            code: e.code,
            keyCode: e.keyCode,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.documentKeyUp, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.documentKeyUp, data));
    }
    OnDocumentMouseDown(e) {
        this.SetInputFocus(false);
    }
    OnDocumentMouseUp(e) {
        let data = {
            code: "ControlLeft",
            keyCode: 17,
        };
        this.OnDispatchOuterEvent(E_EVENT_TYPE.documentMouseUp, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.documentMouseUp, data));
    }
    OnDocumentClick(e) {
        editorLogger.LogDebug("OnDocumentClick");
    }
    getElementPos(element) {
        var actualLeft = element.offsetLeft;
        var actualTop = element.offsetTop;
        var current = element.offsetParent;
        while (current !== null) {
            actualLeft += current.offsetLeft;
            actualTop += current.offsetTop;
            current = current.offsetParent;
        }
        return { elePageX: actualLeft, elePageY: actualTop };
    }
    OnDocumentMouseMove(e) {
        var canvasObj = this.canvas;
        let elePagePos = this.getElementPos(canvasObj);
        let data = {
            posX: e.pageX - elePagePos.elePageX,
            posY: e.pageY - elePagePos.elePageY,
        };
        data.posX = e.pageX - elePagePos.elePageX;
        data.posY = e.pageY - elePagePos.elePageY;
        this.OnDispatchOuterEvent(E_EVENT_TYPE.documentMouseMove, new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_TYPE.documentMouseMove, data));
    }
    OnDocumentPaste(e) {
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        e.preventDefault();
        let htmlStr = e.clipboardData.getData("text/html");
        let textStr = e.clipboardData.getData("text/plain");
        let xmlStr = e.clipboardData.getData("web text/xml");
        let textExStr = e.clipboardData.getData("web text/plain");
        this.DoPaste(xmlStr, textExStr, htmlStr, textStr);
        let items = (e.clipboardData || window.ClipboardItem).items;
        let thinkeditor = this;
        if (items && items.length) {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (item.type.indexOf("image") !== -1) {
                    let file = item.getAsFile();
                    if (file == null || file.size === 0) {
                        return;
                    }
                    var reader = new FileReader();
                    reader.onload = (function (theFile) {
                        return function (e) {
                            if (e.target != null && e.target.result != null) {
                                var binary = e.target.result;
                                var jsonCfg = {
                                    paste: true,
                                };
                                let arrayBuffer = binary;
                                thinkeditor.InsertImage("", 0, 0, theFile.name, arrayBuffer, arrayBuffer.byteLength, jsonCfg);
                            }
                        };
                    })(file);
                    reader.readAsArrayBuffer(file);
                    break;
                }
            }
        }
    }
    async OnDocumentPasteV2(e) {
        editorLogger.LogDebug("document paste");
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        e.preventDefault();
        if ((await this.PasteV2(E_PASTE_TYPE.Normal)) == true) {
            return;
        }
    }
    OnDocumentCopyV2(e) {
        editorLogger.LogEvent(new Date(), `OnDocumentCopyV2: `, e);
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        e.preventDefault();
        this.WriteClipboardV2();
        this.SetDocConfig("", { Copy: true });
    }
    OnDocumentCopy(e) {
        editorLogger.LogEvent(new Date(), `OnDocumentCopy: `, e);
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        e.preventDefault();
        this.WriteClipboard(e);
        this.SetDocConfig("", { Copy: true });
    }
    OnDocumentCutV2(e) {
        editorLogger.LogEvent(new Date(), `OnDocumentCutV2: `, e);
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        e.preventDefault();
        this.WriteClipboardV2();
        this.SetDocConfig("", { Cut: true });
    }
    OnDocumentCut(e) {
        editorLogger.LogEvent(new Date(), `OnDocumentCut: `, e);
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        e.preventDefault();
        this.WriteClipboard(e);
        this.SetDocConfig("", { Cut: true });
    }
    OnSetCursorStyle(evt) {
        if (this.canvas === undefined) {
            return;
        }
        let e = evt;
        let data = e.data.data;
        this.canvas.style.cursor = data.style;
    }
    async OnTextEditFocused(evt) {
        if (this.input === undefined ||
            this.canvas === undefined) {
            return;
        }
        let e = evt;
        editorLogger.LogEvent(new Date(), `OnTextEditFocused:`, e);
        let data = e.data.data;
        let input = this.input;
        let canvas = this.canvas;
        input.style.left = canvas.offsetLeft + data.posX + "px";
        var offsetCanvasTop = data.posY + data.height;
        if (offsetCanvasTop > canvas.offsetHeight) {
            offsetCanvasTop = canvas.offsetHeight;
        }
        input.style.top = canvas.offsetTop + offsetCanvasTop - input.offsetHeight + "px";
    }
    CreateTimeStampEvent(timeStampType) {
        let eventKey = "timeStamp";
        this.dispatchEvent(new ThinkEditorEvent(eventKey, new ThinkEditorEventData(E_EVENT_HANDLE.event, eventKey, { type: timeStampType, time: new Date().toISOString() }, this)));
    }
    OnTimeStamp(evt) {
        let e = evt;
        let data = e.data.data;
        let timeType = data.type;
        let timeStr = data.time;
        switch (timeType) {
            case E_EVENT_KEY.beginParseTime:
                this.timestamp.beginParseTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.endParseTime:
                this.timestamp.endParseTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.beginDownloadFontTime:
                this.timestamp.beginDownloadFontTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.endDownloadFontTime:
                this.timestamp.endDownloadFontTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.beginProduceLines:
                this.timestamp.beginProduceLinesTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.endProduceLines:
                this.timestamp.endProduceLinesTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.beginProducePages:
                this.timestamp.beginProducePagesTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.endProducePages:
                this.timestamp.endProducePagesTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.beginRequestFont:
                this.timestamp.beginRequestFontTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.endRequestFont:
                this.timestamp.endRequestFontTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.beginParseFont:
                this.timestamp.beginParseFontTime = new Date(timeStr);
                break;
            case E_EVENT_KEY.endParseFont:
                this.timestamp.endParseFontTime = new Date(timeStr);
                break;
            default:
                break;
        }
    }
    OnSetOptionsEvent(e) {
        optionsManager.AddOptions(e.data.data, e.data.forceUpdate);
    }
    OnSetOptionsRequest(e) {
        var requestOptionIds = e.data.data;
        var setOptionIds = [];
        for (let requestOptionId of requestOptionIds) {
            var optionId = optionsManager.GetOption(requestOptionId.optionClass, requestOptionId.optionId);
            if (optionId !== undefined) {
                setOptionIds.push(optionId);
            }
        }
        if (setOptionIds.length > 0) {
            this.SetOptions(setOptionIds);
        }
        else {
            this.SetOptions(optionsManager.GetAllOptions());
        }
    }
    OnSetOptions(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.event) {
            this.OnSetOptionsEvent(e);
        }
        else if (e.data.handle == E_EVENT_HANDLE.request) {
            this.OnSetOptionsRequest(e);
        }
    }
    OnDelOptions(evt) {
        let e = evt;
        var requestOptionIds = e.data.data;
        for (let requestOptionId of requestOptionIds) {
            optionsManager.DeleteOption(requestOptionId.optionClass, requestOptionId.optionId);
        }
    }
    OnSetSourcesEvent(e) {
        let sourcesManager = this.sourcesManager;
    }
    OnSetSourcesRequest(e) {
        let sourcesManager = this.sourcesManager;
        var requestSources = e.data.data;
        let setSources = [];
        let missSources = [];
        for (let requestSource of requestSources) {
            let sourceItem = sourcesManager.GetSource(requestSource.sourceClass, requestSource.sourceId);
            if (sourceItem === undefined || sourceItem.content === undefined) {
                missSources.push(requestSource);
            }
            else {
                setSources.push(sourceItem);
            }
        }
        if (setSources.length > 0) {
            this.SetSources(setSources);
        }
        else {
            this.SetSources(sourcesManager.GetAllSources());
        }
    }
    OnSetSources(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.event) {
            this.OnSetSourcesEvent(e);
        }
        else if (e.data.handle == E_EVENT_HANDLE.request) {
            this.OnSetSourcesRequest(e);
        }
    }
    OnSetSearchHistoryEvent(evt) {
        let e = evt;
        shareDataManager.SetSearchHistory(e.data.data);
    }
    OnSetSearchHistoryRequest(evt) {
        let searchHistory = shareDataManager.GetSearchHistory();
        this.SetEditorConfig({ setSearchHistory: searchHistory });
    }
    OnSetSearchHistory(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.event) {
            this.OnSetSearchHistoryEvent(e);
        }
        else if (e.data.handle == E_EVENT_HANDLE.request) {
            this.OnSetSearchHistoryRequest(e);
        }
    }
    OnSetReplaceHistoryEvent(evt) {
        let e = evt;
        shareDataManager.SetReplaceHistory(e.data.data);
    }
    OnSetReplaceHistoryRequest(evt) {
        let replaceHistory = shareDataManager.GetReplaceHistory();
        this.SetEditorConfig({ setReplaceHistory: replaceHistory });
    }
    OnSetReplaceHistory(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.event) {
            this.OnSetReplaceHistoryEvent(e);
        }
        else if (e.data.handle == E_EVENT_HANDLE.request) {
            this.OnSetReplaceHistoryRequest(e);
        }
    }
    OnSetSpecialSymbolHistoryHistoryEvent(evt) {
        let e = evt;
        shareDataManager.SetSpecialSymbolHistory(e.data.data);
    }
    OnSetSpecialSymbolHistoryHistoryRequest(evt) {
        let history = shareDataManager.GetSpecialSymbolHistory();
        this.SetEditorConfig({ setSpecialSymbolHistory: history });
    }
    OnSetSpecialSymbolHistory(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.event) {
            this.OnSetSpecialSymbolHistoryHistoryEvent(e);
        }
        else if (e.data.handle == E_EVENT_HANDLE.request) {
            this.OnSetSpecialSymbolHistoryHistoryRequest(e);
        }
    }
    OnFontsName(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.request) {
            this.SetEditorConfig({ KEY_fontsName: fontsManager.fontsNameList });
        }
    }
    OnSetAlgorithmsEvent(e) {
    }
    OnSetAlgorithmsRequest(e) {
        var requestAlgorithms = e.data.data;
        let setAlgorithms = [];
        let missAlgorithms = [];
        for (let algorithm of requestAlgorithms) {
            let algorithmItem = algorithmsManager.GetAlgorithm(algorithm.algoGroup);
            if (algorithmItem === undefined) {
                missAlgorithms.push(algorithm);
            }
            else {
                setAlgorithms.push(algorithmItem);
            }
        }
        if (setAlgorithms.length > 0) {
            this.SetAlgorithms(setAlgorithms);
        }
        else {
            this.SetAlgorithms(algorithmsManager.algorithms);
        }
    }
    OnSetAlgorithms(evt) {
        let e = evt;
        if (e.data.handle == E_EVENT_HANDLE.event) {
            this.OnSetAlgorithmsEvent(e);
        }
        else if (e.data.handle == E_EVENT_HANDLE.request) {
            this.OnSetAlgorithmsRequest(e);
        }
    }
    async OnExecuteElementScript(evt) {
        let e = evt;
        let thinkEditor = e.data.editor;
        let eventData = e.data;
        let bodyData = eventData.data;
        let eleId = bodyData.id;
        let script = bodyData.script;
        let scriptAction = script === null || script === void 0 ? void 0 : script.action;
        let scriptEvent = script === null || script === void 0 ? void 0 : script.event;
        let text = bodyData.text;
        let value = bodyData.text;
        if (scriptAction !== undefined && scriptAction !== "") {
            try {
                scriptAction = scriptAction.replace(/[\r\n]/g, "");
                eval(scriptAction);
                if (bodyData.checkScriptSyntax) {
                    alert(`JavaScript脚本合法！`);
                }
            }
            catch (err) {
                let alertStr = `eleId: [${eleId}] err: [${err}] 执行元素脚本Script:${scriptAction} 异常！`;
                editorLogger.LogError(alertStr);
                if (bodyData.checkScriptSyntax) {
                    alert(alertStr);
                }
            }
        }
    }
    async OnPrintDoc(evt) {
        let e = evt;
        let data = e.data.data;
        if (e.data.handle == E_EVENT_HANDLE.action) {
            this.PrintDoc(data);
        }
    }
    async OnRequestFont(evt) {
        let e = evt;
        let data = e.data.data;
        return await this.LoadRequestFontProcess(data);
    }
    async LoadRequestFontProcessSpeedMode(data) {
        editorLogger.LogDebug("Font-LoadRequestFontProcessSpeedMode: ", data);
        let fontFile = data.file;
        let fontName = data.fontName;
        let fontData = await this.GetFontData(fontName, undefined);
        if (fontData.byteLength != 0 && this.LoadFont(fontName, fontData)) {
            editorLogger.LogDebug(`Font-加载字体成功。FontsfontsManager.AddFont: [${fontName}]`);
            fontsManager.AddFont(fontName, fontData);
            this.Call("LoadFontEnd", "null", [], []);
            return true;
        }
        let candidateFontInfoArr = data.candidateFontInfo;
        for (const candidateFontInfo of candidateFontInfoArr) {
            let candidateFontFile = candidateFontInfo.file;
            let candidateFontName = candidateFontInfo.fontName;
            let candidateFontData = await this.GetFontData(candidateFontName, undefined);
            if (candidateFontData.byteLength != 0 && this.LoadFont(candidateFontName, candidateFontData)) {
                editorLogger.LogDebug(`Font-加载候选字体成功。FontsfontsManager.AddFont: [${fontName}]`);
                fontsManager.AddFont(candidateFontName, candidateFontData);
                this.LoadFont(fontName, new ArrayBuffer(0));
                this.Call("LoadFontEnd", "null", [], []);
                return true;
            }
            else {
                editorLogger.LogWarn(`Font-标记加载候选字体失败: [${candidateFontName}]-[${candidateFontFile}]`);
            }
        }
        fontData = await this.GetFontData(undefined, fontFile);
        if (this.LoadFont(fontName, fontData)) {
            editorLogger.LogDebug(`Font-加载字体成功。FontsfontsManager.AddFont: [${fontName}]`);
            fontsManager.AddFont(fontName, fontData);
            this.Call("LoadFontEnd", "null", [], []);
            return true;
        }
        candidateFontInfoArr = data.candidateFontInfo;
        for (const candidateFontInfo of candidateFontInfoArr) {
            let candidateFontFile = candidateFontInfo.file;
            let candidateFontName = candidateFontInfo.fontName;
            let candidateFontData = await this.GetFontData(undefined, candidateFontFile);
            if (this.LoadFont(candidateFontName, candidateFontData)) {
                editorLogger.LogDebug(`Font-加载候选字体成功。FontsfontsManager.AddFont: [${fontName}]`);
                fontsManager.AddFont(candidateFontName, candidateFontData);
                this.Call("LoadFontEnd", "null", [], []);
                return true;
            }
            else {
                editorLogger.LogWarn(`Font-标记加载候选字体失败: [${candidateFontName}]-[${candidateFontFile}]`);
            }
        }
        this.Call("LoadFontEnd", "null", [], []);
    }
    async LoadRequestFontProcessStrictMode(data) {
        editorLogger.LogDebug("Font-LoadRequestFontProcessStrictMode: ", data);
        let fontFile = data.file;
        let fontName = data.fontName;
        let fontData = await this.GetFontData(fontName, fontFile);
        if (this.LoadFont(fontName, fontData)) {
            editorLogger.LogDebug(`Font-加载字体成功。FontsfontsManager.AddFont: [${fontName}]`);
            fontsManager.AddFont(fontName, fontData);
            this.Call("LoadFontEnd", "null", [], []);
            return true;
        }
        let candidateFontInfoArr = data.candidateFontInfo;
        for (const candidateFontInfo of candidateFontInfoArr) {
            let candidateFontFile = candidateFontInfo.file;
            let candidateFontName = candidateFontInfo.fontName;
            let candidateFontData = await this.GetFontData(candidateFontName, candidateFontFile);
            if (this.LoadFont(candidateFontName, candidateFontData)) {
                editorLogger.LogDebug(`Font-加载候选字体成功。FontsfontsManager.AddFont: [${fontName}]`);
                fontsManager.AddFont(candidateFontName, candidateFontData);
                this.Call("LoadFontEnd", "null", [], []);
                return true;
            }
            else {
                editorLogger.LogWarn(`Font-标记加载候选字体失败: [${candidateFontName}]-[${candidateFontFile}]`);
            }
        }
        this.Call("LoadFontEnd", "null", [], []);
        return false;
    }
    async LoadRequestFontProcess(data) {
        return await this.LoadRequestFontProcessStrictMode(data);
    }
    async GetFontData(fontName, fontFile) {
        if (fontName != "" && fontName !== undefined) {
            let fontData = await fontsManager.GetFontData(fontName);
            if (fontData !== undefined) {
                editorLogger.LogDebug(`Font-A从FontsManager获得了字体数据[${fontName}]`);
                return fontData;
            }
        }
        if (fontFile != "" && fontFile !== undefined) {
            var url = this.fontPath + fontFile;
            editorLogger.LogDebug("Font-B使用内置方法加载字体数据:" + url);
            try {
                this.CreateTimeStampEvent(E_EVENT_KEY.beginDownloadFontTime);
                let response = await fetch(url, { credentials: "same-origin" });
                if (response.ok) {
                    let data = await response.arrayBuffer();
                    this.CreateTimeStampEvent(E_EVENT_KEY.endDownloadFontTime);
                    return data;
                }
                else {
                    editorLogger.LogError("Font-fetch fontfile error!");
                    return new ArrayBuffer(0);
                }
            }
            catch (e) {
                editorLogger.LogError("Font-fetch fontfile catch error:", e);
                return new ArrayBuffer(0);
            }
        }
        return new ArrayBuffer(0);
    }
    addEventListener(type, callback, options) {
        if (callback) {
            const thisCallback = callback.bind(this);
            super.addEventListener(type, async (e) => thisCallback(e), options);
        }
    }
    NeedSetInputFocus() {
        if (this.forbidCallAutoSetInputFocus == undefined || this.forbidCallAutoSetInputFocus == false) {
            return true;
        }
        return false;
    }
    Call(ident, returnType, argTypes, args, opts) {
        if (this.instance === undefined) {
            editorLogger.LogWarn("当前ThinkEditor未Init或已经UnInit,请检查业务流程是否合理！", ident);
            return;
        }
        if (this.NeedSetInputFocus()) {
            this.SetInputFocus(true);
        }
        return this.instance.ccall(ident, returnType, argTypes, args, opts);
    }
    GetViewFrameData() {
        return this.instance.ccall("GetViewFrameData", "framedata", ["null"], ["null"]);
    }
    CanCopyToOuter() {
        var editorProperties = this.GetDocProperties();
        return !editorProperties.behavior.forbidCopyToOuter;
    }
    CanPasteFromOuter() {
        var editorProperties = this.GetDocProperties();
        return !editorProperties.behavior.forbidPasteFromOuter;
    }
    SetFontConfig(jsonObj = {}) {
        return this.Call("SetFontConfig", "bool", ["object"], [jsonObj]);
    }
    SetInputFocus(state) {
        if (state && this.input !== undefined) {
            editorLogger.LogDebug(new Date(), "input.focus()");
            this.input.focus();
        }
    }
    SetDocConfig(docName, jsonObj) {
        return this.Call("SetDocConfig", "bool", ["string", "object"], [docName, jsonObj]);
    }
    IsEditorFocus() {
        return this.isInputFocus;
    }
    GetSelectRangeContent(docFormat, jsonCfg = {}) {
        return this.Call("GetSelectRangeContent", "string*", ["number", "object"], [docFormat, jsonCfg]);
    }
    InsertImage(id, width, height, filename, arrayBuffer, data_len, jsonObj) {
        return this.Call("InsertImage", "string*", ["string", "number", "number", "string", "arraybuffer*", "number", "object"], [id, width, height, filename, arrayBuffer, arrayBuffer.byteLength, jsonObj]);
    }
    InputFragmentPaste(docFormat, fragment, jsonCfg = {}) {
        return this.Call("InputFragmentPaste", "bool", ["number", "string*", "object"], [docFormat, fragment, jsonCfg]);
    }
    LoadFont(fontName, arrayBuffer) {
        let result = this.Call("LoadFont", "bool", ["string", "arraybuffer*", "number"], [fontName, arrayBuffer, arrayBuffer.byteLength]);
        return result;
    }
    LoadRequestFont(fontName, arrayBuffer, candidateFontInfo) {
        let result = this.Call("LoadRequestFont", "bool", ["string", "arraybuffer*", "number", "object"], [fontName, arrayBuffer, arrayBuffer.byteLength, candidateFontInfo]);
        return result;
    }
    ResizeViewPanel(width, heigth) {
        return this.instance.ccall("ResizeViewPanel", "bool", ["number", "number"], [width, heigth]);
    }
    SetEditorConfig(object) {
        return this.Call("SetEditorConfig", "bool", ["object"], [object]);
    }
    InputText(text) {
        return this.Call("InputText", "bool", ["string"], [text]);
    }
    SetSystemSource(sourceClass, sourceId, fragment) {
        return this.Call("SetSystemSource", "bool", ["string", "string", "string"], [sourceClass, sourceId, fragment]);
    }
    SetSource(sourceClass, sourceId, fragment) {
        return this.Call("SetSource", "bool", ["string", "string", "string"], [sourceClass, sourceId, fragment]);
    }
    SetSources(sources, jsonCfg = {}) {
        return this.Call("SetSources", "bool", ["object", "object"], [sources, jsonCfg]);
    }
    AddSystemKnowledge(knowledge) {
        return this.Call("AddSystemKnowledge", "bool", ["string | ArrayBuffer"], [knowledge]);
    }
    AddDocKnowledge(knowledge) {
        return this.Call("AddDocKnowledge", "bool", ["string | ArrayBuffer"], [knowledge]);
    }
    SetOptions(options) {
        return this.Call("SetOptions", "bool", ["object"], [options]);
    }
    DelOptions(options) {
        return this.Call("DelOptions", "bool", ["object"], [options]);
    }
    SetAlgorithms(algorithms) {
        return this.Call("SetAlgorithms", "bool", ["object"], [algorithms]);
    }
    DelAlgorithms(algorithms) {
        return this.Call("DelAlgorithms", "bool", ["object"], [algorithms]);
    }
    SetDefaultFont(fontName, fontSize) {
        return this.Call("SetDefaultFont", "bool", ["string", "string"], [fontName, fontSize]);
    }
    SetViewMode(viewMode, cfg = {}) {
        this.Call("SetViewMode", "null", ["number", "object"], [viewMode, cfg]);
    }
    CreateDoc(docName) {
        return this.Call("CreateDoc", "bool", ["string"], [docName]);
    }
    ParseDoc(docName, xml, jsonCfg) {
        return this.ParseDocs(docName, "", xml, jsonCfg);
    }
    ParseDocs(docName, subDocName, subDocXml, jsonCfg) {
        this.CreateTimeStampEvent(E_EVENT_KEY.beginParseTime);
        let result;
        if (typeof subDocXml == "string") {
            editorLogger.LogDebug("ParseDocs String");
            result = this.Call("ParseDocs", "bool", ["string", "string", "string*", "object"], [docName, subDocName, subDocXml, jsonCfg]);
        }
        else {
            editorLogger.LogDebug("ParseDocs Uint8Array");
            result = this.Call("ParseDocs", "bool", ["string", "string", "arraybuffer*", "object"], [docName, subDocName, subDocXml, jsonCfg]);
        }
        this.CreateTimeStampEvent(E_EVENT_KEY.endParseTime);
        return result;
    }
    SelectDoc(docName, jsonCfg = {}) {
        return this.Call("SelectDoc", "bool", ["string", "object"], [docName, jsonCfg]);
    }
    CloseDoc(docName) {
        this.Call("CloseDoc", "null", ["string"], [docName]);
    }
    CloseAllDoc() {
        this.Call("CloseAllDoc", "null", ["null"], ["null"]);
    }
    SetUserProfile(jsonCfg) {
        return this.Call("SetUserProfile", "bool", ["object"], [jsonCfg]);
    }
    SetEditorInfo(terminal, editorId, editorName, permissionLevel, jsonAttrs) {
        return this.Call("SetEditorInfo", "bool", ["string", "string", "string", "number", "object"], [terminal, editorId, editorName, permissionLevel, jsonAttrs]);
    }
    UpdateEditorInfo(optId, terminal, editorId, editorName, permissionLevel, editorTime, jsonAttrs) {
        return this.Call("UpdateEditorInfo", "bool", ["string", "string", "string", "string", "number", "string", "object"], [optId, terminal, editorId, editorName, permissionLevel, editorTime, jsonAttrs]);
    }
    SetDocType(docType) {
        return this.Call("SetDocType", "bool", ["number"], [docType]);
    }
    SetEditMode(enablePermission, enableRevise, modifySameLevel, fristEditByRevise, modifyOwnerOldByRevise = 0) {
        return this.Call("SetEditMode", "bool", ["number", "number", "number", "number", "number"], [enablePermission, enableRevise, modifySameLevel, fristEditByRevise, modifyOwnerOldByRevise]);
    }
    SetDocClass(docClass) {
        return this.Call("SetDocClass", "bool", ["string"], [docClass]);
    }
    SetDisplayScale(pagesLayoutMode, value) {
        this.Call("SetDisplayScale", "null", ["number", "number"], [pagesLayoutMode, value]);
    }
    InsertSeparator(width, height, jsonCfg) {
        return this.Call("InsertSeparator", "bool", ["number", "number", "object"], [width, height, jsonCfg]);
    }
    InsertPageFeed(count) {
        return this.Call("InsertPageFeed", "bool", ["number"], [count]);
    }
    InsertLineFeed(count) {
        return this.Call("InsertLineFeed", "bool", ["number"], [count]);
    }
    InsertFormula(jsonCfg) {
        editorLogger.LogDebug(jsonCfg);
        return this.Call("InsertFormula", "bool", ["object"], [jsonCfg]);
    }
    SetFormulaConfig(id, jsonCfg) {
        return this.Call("SetFormulaConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    InsertBarCode(jsonCfg) {
        return this.Call("InsertBarCode", "bool", ["object"], [jsonCfg]);
    }
    SetBarCodeConfig(id, jsonCfg) {
        return this.Call("SetBarCodeConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    GetBarCodeClass(barcodeType) {
        return this.Call("GetBarCodeClass", "number", ["number"], [barcodeType]);
    }
    SetBarCodeIdentity(id, newId, newName) {
        return this.Call("SetBarCodeIdentity", "bool", ["string", "string", "string"], [id, newId, newName]);
    }
    GetBarCodeList() {
        return this.Call("GetBarCodeList", "object", ["null"], ["null"]);
    }
    CalcBarCodeProperties(jsonCfg) {
        return this.Call("CalcBarCodeProperties", "object", ["object"], [jsonCfg]);
    }
    InsertAnnotate(id, fragment, jsonCfg) {
        return this.Call("InsertAnnotate", "string*", ["string", "string", "object"], [id, fragment, jsonCfg]);
    }
    InsertButton(jsonCfg) {
        return this.Call("InsertButton", "bool", ["object"], [jsonCfg]);
    }
    InsertComment(id, fragment, jsonCfg) {
        return this.Call("InsertComment", "string*", ["string", "string", "object"], [id, fragment, jsonCfg]);
    }
    DeleteComment(jsonCfg) {
        return this.Call("DeleteComment", "bool", ["object"], [jsonCfg]);
    }
    InsertReplyComment(fragment, jsonCfg) {
        return this.Call("InsertReplyComment", "bool", ["string", "object"], [fragment, jsonCfg]);
    }
    SetCommentResolve(jsonCfg) {
        return this.Call("SetCommentResolve", "bool", ["object"], [jsonCfg]);
    }
    DeleteReplyComment(jsonCfg) {
        return this.Call("DeleteReplyComment", "bool", ["object"], [jsonCfg]);
    }
    SelectComments(jsonCfg) {
        return this.Call("SelectComments", "bool", ["object"], [jsonCfg]);
    }
    SelectComment(jsonCfg) {
        return this.Call("SelectComment", "bool", ["object"], [jsonCfg]);
    }
    GetCommentReport() {
        return this.Call("GetCommentReport", "object", ["null"], ["null"]);
    }
    SelectQualityControl(jsonCfg) {
        return this.Call("SelectQualityControl", "bool", ["object"], [jsonCfg]);
    }
    InsertElement(jsonCfg) {
        return this.Call("InsertElement", "bool", ["object"], [jsonCfg]);
    }
    DeleteElement(id, jsonCfg) {
        return this.Call("DeleteElement", "bool", ["string", "object"], [id, jsonCfg]);
    }
    InsertCheckBox(jsonCfg) {
        return this.Call("InsertCheckBox", "bool", ["object"], [jsonCfg]);
    }
    InsertCheckBoxGroup(jsonCfg) {
        return this.Call("InsertCheckBoxGroup", "bool", ["object"], [jsonCfg]);
    }
    SetCheckBoxConfig(id, jsonCfg) {
        return this.Call("SetCheckBoxConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    InsertPageNum(jsonCfg) {
        return this.Call("InsertPageNum", "bool", ["object"], [jsonCfg]);
    }
    SetPageNumConfig(id, jsonCfg) {
        return this.Call("SetPageNumConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    GetTableProperties(tableId) {
        return this.Call("GetTableProperties", "object", ["string"], [tableId]);
    }
    InsertTable(jsonCfg) {
        return this.Call("InsertTable", "bool", ["object"], [jsonCfg]);
    }
    SelectTable(id, jsonCfg) {
        return this.Call("SelectTable", "bool", ["string", "object"], [id, jsonCfg]);
    }
    TableInsertOpt(insertMode) {
        this.Call("TableInsertOpt", "null", ["number"], [insertMode]);
    }
    TableDeleteOpt(deleteMode) {
        this.Call("TableDeleteOpt", "null", ["number"], [deleteMode]);
    }
    CellOpt(mode) {
        this.Call("CellOpt", "null", ["number"], [mode]);
    }
    SetTableConfig(id, jsonCfg) {
        return this.Call("SetTableConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    SetTableData(id, tableXml, jsonCfg) {
        let result;
        if (typeof tableXml == "string") {
            result = this.Call("SetTableData", "bool", ["string", "string*", "object"], [id, tableXml, jsonCfg]);
        }
        else {
            result = this.Call("SetTableData", "bool", ["string", "arraybuffer*", "object"], [id, tableXml, jsonCfg]);
        }
        return result;
    }
    SetCellContent(param) {
        if (param.units !== undefined) {
            return this.SetCellContentCombine(param);
        }
        return this.Call("SetCellContent", "bool", ["object"], [param]);
    }
    SetCellContentCombine(param) {
        return this.Call("SetCellContentCombine", "bool", ["object"], [param]);
    }
    SetTableAlign(mode) {
        this.Call("SetTableAlign", "null", ["number"], [mode]);
    }
    SetCellAlignContent(mode) {
        this.Call("SetCellAlignContent", "null", ["number"], [mode]);
    }
    InsertParagraph(jsonCfg) {
        return this.Call("InsertParagraph", "bool", ["object"], [jsonCfg]);
    }
    DeleteParagraph(jsonCfg) {
        return this.Call("DeleteParagraph", "bool", ["object"], [jsonCfg]);
    }
    SetParagraphConfig(id, jsonCfg) {
        return this.Call("SetParagraphConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    SetElementConfig(id, jsonCfg) {
        return this.Call("SetElementConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    SetElementBase(id, hint, tip, startBorder, endBorder, beforeTag, afterTag) {
        return this.Call("SetElementBase", "bool", ["string", "string", "string", "string", "string", "string", "string"], [id, hint, tip, startBorder, endBorder, beforeTag, afterTag]);
    }
    SetElementIdentity(id, newId, newName) {
        return this.Call("SetElementIdentity", "bool", ["string", "string", "string"], [id, newId, newName]);
    }
    SetElementAttributes(id, attrs) {
        return this.Call("SetElementAttributes", "bool", ["string", "object"], [id, attrs]);
    }
    AddElementAttribute(id, key, value) {
        return this.Call("AddElementAttribute", "bool", ["string", "string", "string"], [id, key, value]);
    }
    SetElementSelectItems(id, itemsStr) {
        return this.Call("SetElementSelectItems", "null", ["string", "string"], [id, itemsStr]);
    }
    SetElementContent(id, content, jsonCfg = {}) {
        return this.Call("SetElementContent", "bool", ["string", "string", "string"], [id, content, jsonCfg]);
    }
    SetElementsContent(keyValues, jsonCfg = {}) {
        return this.Call("SetElementsContent", "bool", ["object", "object"], [keyValues, jsonCfg]);
    }
    GetElementProperties(jsonCfg = {}) {
        return this.Call("GetElementProperties", "object", ["object"], [jsonCfg]);
    }
    GetElementsContent(ids = [], jsonCfg = {}) {
        jsonCfg.mode = ids.length > 0 ? 2 : 1;
        jsonCfg.ids = ids;
        jsonCfg.onlyContent = true;
        return this.GetElementProperties(jsonCfg);
    }
    SetElementInputText(id) {
        return this.Call("SetElementInputText", "bool", ["string"], [id]);
    }
    SetElementInputNumber(id) {
        return this.Call("SetElementInputNumber", "bool", ["string"], [id]);
    }
    SetElementSelectOption(id, optionClass, optionId, itemLinkStr, elementCfg) {
        return this.Call("SetElementSelectOption", "bool", ["string", "string", "string", "string", "number"], [id, optionClass, optionId, itemLinkStr, elementCfg.all]);
    }
    SetElementSelectDateTime(id, dateType) {
        return this.Call("SetElementSelectDateTime", "bool", ["string", "number"], [id, dateType]);
    }
    SetElementQCNoticeMsg(id, msgLevel, msg) {
        return this.Call("SetElementQCNoticeMsg", "bool", ["string", "number", "string"], [id, msgLevel, msg]);
    }
    SetElementQCString(id, minLen, maxLen, qcCfg) {
        return this.Call("SetElementQCString", "bool", ["string", "number", "number", "number"], [id, minLen, maxLen, qcCfg.all]);
    }
    SetElementQCNumber(id, minValue, maxValue, decimalCount, qcCfg) {
        return this.Call("SetElementQCNumber", "bool", ["string", "number", "number", "number", "number"], [id, minValue, maxValue, decimalCount, qcCfg.all]);
    }
    SetElementQCDateTime(id, minDatetime, maxDatetime, qcCfg) {
        return this.Call("SetElementQCDateTime", "bool", ["string", "string", "string", "number"], [id, minDatetime.toString(), maxDatetime.toString(), qcCfg.all]);
    }
    SetElementExcludeKeywords(id, keywords) {
        return this.Call("SetElementExcludeKeywords", "bool", ["string", "string"], [id, keywords]);
    }
    SetElementSource(id, sourceClass, sourceId, elementCfg) {
        return this.Call("SetElementSource", "bool", ["string", "string", "string", "number"], [id, sourceClass, sourceId, elementCfg.all]);
    }
    InsertImageBase64(id, width, height, filename, base64Str, base64StrLen, jsonCfg) {
        return this.Call("InsertImageBase64", "bool", ["string", "number", "number", "string", "string*", "number", "object"], [id, width, height, filename, base64Str, base64StrLen, jsonCfg]);
    }
    SetImageConfig(id, jsonCfg) {
        return this.Call("SetImageConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    SetImageResource(imageName, arrayBuffer, dataLen, jsonCfg) {
        return this.Call("SetImageResource", "string*", ["string", "arraybuffer*", "number", "object"], [imageName, arrayBuffer, dataLen, jsonCfg]);
    }
    SetImageResourceBase64(fileName, base64Str, base64StrLen, jsonCfg) {
        return this.Call("SetImageResourceBase64", "string*", ["string", "string*", "number", "object"], [fileName, base64Str, base64StrLen, jsonCfg]);
    }
    GetDoc(docFormat, docType, jsonCfg) {
        return this.Call("GetDoc", "string*", ["number", "number", "object"], [docFormat, docType, jsonCfg]);
    }
    GetSourceList(docName) {
        return this.Call("GetSourceList", "object", ["string"], [docName]);
    }
    GetAlgorithmList() {
        return this.Call("GetAlgorithmList", "object", ["null"], ["null"]);
    }
    GetOptionList() {
        return this.Call("GetOptionList", "object", ["null"], ["null"]);
    }
    GetSelectDocName() {
        return this.Call("GetSelectDocName", "string*", ["null"], ["null"]);
    }
    GetVisibleDocName() {
        return this.Call("GetVisibleDocName", "string*", ["null"], ["null"]);
    }
    GetPagesImage(imageType, startIndex, pagesCount, scale) {
        return this.Call("GetPagesImage", "uint8array", ["number", "number", "number", "number"], [imageType, startIndex, pagesCount, scale]);
    }
    GetPagesPDF(startIndex, pagesCount, scale, jsonCfg) {
        return this.Call("GetPagesPDF", "uint8array", ["number", "number", "number", "object"], [startIndex, pagesCount, scale, jsonCfg]);
    }
    async PrintPDF(jsonCfg) {
        return await this.PrintPdfAsync(jsonCfg);
    }
    async PrintPdfAsync(jsonCfg) {
        var dataBuffer = await this.GetPdfAsync(jsonCfg);
        let blob = new Blob([dataBuffer], { type: "application/pdf" });
        let blobURL = URL.createObjectURL(blob);
        var iframe = GetPrintIframe();
        iframe.style.display = "none";
        iframe.src = blobURL;
        iframe.onload = function () {
            setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    }
    async GetLostFontinternal() {
        let loop = true;
        while (loop) {
            let lostFontInfos = this.GetLostFontInfos();
            if (lostFontInfos === undefined) {
                break;
            }
            loop = false;
            for (let lostFontInfo of lostFontInfos) {
                let loading = lostFontInfo.loading;
                if (loading) {
                    await Sleep(2000);
                    loop = true;
                    break;
                }
            }
        }
        let lostFontInfos = this.GetLostFontInfos();
        if (lostFontInfos !== undefined) {
            for (let lostFontInfo of lostFontInfos) {
                await this.LoadRequestFontProcess(lostFontInfo);
            }
        }
    }
    async GetPDF(jsonCfg) {
        return await this.GetPdfAsync(jsonCfg);
    }
    async GetPdfAsync(jsonCfg) {
        await this.GetLostFontinternal();
        return this.Call("GetPDF", "uint8array", ["object"], [jsonCfg]);
    }
    GetLostFontInfos() {
        return this.Call("GetLostFontInfos", "object", ["null"], ["null"]);
    }
    GetObjectFragment(identityObjectType, id, docFormat, jsonCfg) {
        return this.Call("GetObjectFragment", "string*", ["number", "string", "number", "object"], [identityObjectType, id, docFormat, jsonCfg]);
    }
    PrintDoc(jsonCfg) {
        return this.PrintPDF(jsonCfg);
    }
    InputData(data) {
        return this.Call("InputData", "bool", ["string*"], [data]);
    }
    InputFragment(fragment, jsonCfg) {
        if (typeof fragment == "string") {
            return this.Call("InputFragment", "bool", ["string*", "object"], [fragment, jsonCfg]);
        }
        else {
            return this.Call("InputFragment", "bool", ["arraybuffer*", "object"], [fragment, jsonCfg]);
        }
    }
    AppendFragment(docName, fragment, jsonCfg) {
        if (typeof fragment == "string") {
            return this.Call("AppendFragment", "bool", ["string", "string*", "object"], [docName, fragment, jsonCfg]);
        }
        else {
            return this.Call("AppendFragment", "bool", ["string", "arraybuffer*", "object"], [docName, fragment, jsonCfg]);
        }
    }
    UpdateServerTime(serverTime) {
        this.Call("UpdateServerTime", "null", ["string"], [serverTime]);
    }
    NewDoc(docName, docType) {
        return this.Call("NewDoc", "string", ["string", "number"], [docName, docType]);
    }
    VisibleDoc(docName) {
        return this.Call("VisibleDoc", "bool", ["string"], [docName]);
    }
    SetDocAttributes(atts) {
        this.Call("SetDocAttributes", "null", ["object"], [atts]);
    }
    AddDocAttribute(key, value) {
        this.Call("AddDocAttribute", "null", ["string", "string"], [key, value]);
    }
    GetFocusProperties(jsonCfg = {}) {
        return this.Call("GetFocusProperties", "object", ["object"], [jsonCfg]);
    }
    GetDocProperties() {
        return this.Call("GetDocProperties", "object", ["null"], ["null"]);
    }
    GetEditorProperties() {
        return this.Call("GetEditorProperties", "object", ["null"], ["null"]);
    }
    SetObjectOwner(jsonCfg) {
        return this.Call("SetObjectOwner", "bool", ["object"], [jsonCfg]);
    }
    SetObjectClaim(jsonCfg) {
        return this.Call("SetObjectClaim", "bool", ["object"], [jsonCfg]);
    }
    DisplayPageSetWindow(displayMode) {
        this.Call("DisplayPageSetWindow", "null", ["number"], [displayMode]);
    }
    DisplaySpecialSymbolSetWindow(displayMode) {
        this.Call("DisplaySpecialSymbolSetWindow", "null", ["number"], [displayMode]);
    }
    DisplayInputHandleSelector(displayMode) {
        this.Call("DisplayInputHandleSelector", "null", ["number"], [displayMode]);
    }
    DisplayToothPositionSelector(displayMode) {
        this.Call("DisplayToothPositionSelector", "null", ["number"], [displayMode]);
    }
    ClearFormat() {
        this.Call("ClearFormat", "null", ["null"], ["null"]);
    }
    SetFormatBrush(brushState) {
        this.Call("SetFormatBrush", "null", ["number"], [brushState]);
    }
    Undo(count) {
        this.Call("Undo", "null", ["number"], [count]);
    }
    Redo(count) {
        this.Call("Redo", "null", ["number"], [count]);
    }
    Copy() {
        this.SetInputFocus(true);
        this.Call("Copy", "null", ["null"], ["null"]);
    }
    Cut() {
        this.SetInputFocus(true);
        this.Call("Cut", "null", ["null"], ["null"]);
    }
    Paste(pasteType) {
        this.SetInputFocus(true);
        if (IsHavingAdvancedPermissions()) {
            this.PasteV2(pasteType);
        }
        else {
            this.PasteV1(pasteType);
        }
    }
    Delete() {
        this.Call("Delete", "null", ["null"], ["null"]);
    }
    PasteInner(pasteType) {
        this.Call("Paste", "null", ["number"], [pasteType]);
    }
    async DoPaste(xmlStr, textExStr, htmlStr, textStr, pasteType) {
        if (htmlStr != "" && htmlStr == shareDataManager.clipboard.htmlStr) {
            if (xmlStr == "") {
                xmlStr = shareDataManager.clipboard.xmlStr;
            }
            if (textExStr == "") {
                textExStr = shareDataManager.clipboard.textExStr;
            }
        }
        if ((pasteType === undefined || pasteType == E_PASTE_TYPE.Normal || pasteType == E_PASTE_TYPE.OnlyXml) && this.InputFragmentPaste(E_DOC_FORMAT.XML, xmlStr)) {
            return true;
        }
        if ((pasteType === undefined || pasteType == E_PASTE_TYPE.Normal || pasteType == E_PASTE_TYPE.OnlyText) && this.InputFragmentPaste(E_DOC_FORMAT.TextEx, textExStr)) {
            return true;
        }
        if ((pasteType === undefined || pasteType == E_PASTE_TYPE.Normal) && this.CanPasteFromOuter() && this.InputFragmentPaste(E_DOC_FORMAT.HTML, htmlStr)) {
            return true;
        }
        if ((pasteType === undefined || pasteType == E_PASTE_TYPE.Normal || pasteType == E_PASTE_TYPE.OnlyText) &&
            this.CanPasteFromOuter() &&
            this.InputFragmentPaste(E_DOC_FORMAT.TEXT, textStr)) {
            return true;
        }
        return false;
    }
    async PasteV1(pasteType) {
        return this.DoPaste(shareDataManager.clipboard.xmlStr, shareDataManager.clipboard.textExStr, shareDataManager.clipboard.htmlStr, shareDataManager.clipboard.textStr, pasteType);
    }
    async PasteV2(pasteType) {
        try {
            const clipboard_read = "clipboard-read";
            const permission = await navigator.permissions.query({
                name: clipboard_read,
            });
            if (permission.state === "denied") {
                throw new Error("Not allowed to read clipboard.");
            }
            const clipboardItems = await navigator.clipboard.read();
            var xmlStr = "";
            var textExStr = "";
            var textStr = "";
            var htmlStr = "";
            editorLogger.LogDebug("clipboardItems:" + clipboardItems.length);
            for (const clipboardItem of clipboardItems) {
                editorLogger.LogDebug("clipboardItem.types:" + clipboardItem.types.length);
                for (const type of clipboardItem.types) {
                    editorLogger.LogDebug("clipboardItem.type:" + type);
                    if (type == "text/html") {
                        let blob = await clipboardItem.getType(type);
                        if (blob != null) {
                            htmlStr = await blob.text();
                        }
                    }
                    else if (type == "web text/xml") {
                        let blob = await clipboardItem.getType(type);
                        if (blob != null) {
                            xmlStr = await blob.text();
                        }
                    }
                    else if (type == "web text/plain") {
                        let blob = await clipboardItem.getType(type);
                        if (blob != null) {
                            textExStr = await blob.text();
                        }
                    }
                    else if (type == "text/plain") {
                        let blob = await clipboardItem.getType(type);
                        if (blob != null) {
                            textStr = await blob.text();
                        }
                    }
                    else if (type.startsWith("image")) {
                        let blob = await clipboardItem.getType(type);
                        if (blob != null) {
                            var jsonCfg = {
                                paste: true,
                            };
                            let arrayBuffer = await blob.arrayBuffer();
                            this.InsertImage("", 0, 0, "", arrayBuffer, arrayBuffer.byteLength, jsonCfg);
                        }
                    }
                }
            }
            return this.DoPaste(xmlStr, textExStr, htmlStr, textStr, pasteType);
        }
        catch (err) {
            console.error(err);
        }
    }
    SetFontType(fontName) {
        this.Call("SetFontType", "null", ["string"], [fontName]);
    }
    SetFontSize(fontSize) {
        this.Call("SetFontSize", "null", ["number"], [fontSize]);
    }
    SetFontSizeItem(fontItem) {
        return this.Call("SetFontSizeItem", "bool", ["string"], [fontItem]);
    }
    SetFontScript(scriptMode) {
        this.Call("SetFontScript", "null", ["number"], [scriptMode]);
    }
    SetFontItalic(italic) {
        this.Call("SetFontItalic", "null", ["bool"], [italic]);
    }
    SetFontBold(bold) {
        this.Call("SetFontBold", "null", ["bool"], [bold]);
    }
    SetFontUnderline(underline) {
        this.Call("SetFontUnderline", "null", ["bool"], [underline]);
    }
    SetFontStrikethrough(strikethrough) {
        this.Call("SetFontStrikethrough", "null", ["bool"], [strikethrough]);
    }
    SetFontColor(color) {
        this.Call("SetFontColor", "null", ["string"], [color]);
    }
    SetFontBackColor(color) {
        this.Call("SetFontBackColor", "null", ["string"], [color]);
    }
    SetFontFerrule(mode) {
        this.Call("SetFontFerrule", "null", ["number"], [mode]);
    }
    SetParagraphContent(id, content, jsonCfg) {
        return this.Call("SetParagraphContent", "bool", ["string", "string", "object"], [id, content, jsonCfg]);
    }
    SetParagraphListMode(listMode) {
        return this.Call("SetParagraphListMode", "bool", ["number"], [listMode]);
    }
    SetParagraphListLevel(listLevel) {
        this.Call("SetParagraphListLevel", "null", ["number"], [listLevel]);
    }
    SetParagraphListHeaderStr(headerStr) {
        return this.Call("SetParagraphListHeaderStr", "bool", ["string"], [headerStr]);
    }
    SetParagraphSpecificIndentFormat(specificIndentFormat) {
        this.Call("SetParagraphSpecificIndentFormat", "null", ["number"], [specificIndentFormat]);
    }
    SetParagraphAlignContent(alignmentHorizontalMode) {
        this.Call("SetParagraphAlignContent", "null", ["number"], [alignmentHorizontalMode]);
    }
    SetParagraphLeftIndent(indent) {
        this.Call("SetParagraphLeftIndent", "null", ["number"], [indent]);
    }
    SetParagraphRightIndent(indent) {
        this.Call("SetParagraphRightIndent", "null", ["number"], [indent]);
    }
    SetParagraphSpecificIndentCharacterCount(count) {
        this.Call("SetParagraphSpecificIndentCharacterCount", "null", ["number"], [count]);
    }
    SetParagraphLineSpacingValue(value) {
        this.Call("SetParagraphLineSpacingValue", "null", ["number"], [value]);
    }
    SetParagraphLineSpacingRule(rule) {
        this.Call("SetParagraphLineSpacingRule", "null", ["number"], [rule]);
    }
    SetParagraphSpaceBefore(space) {
        this.Call("SetParagraphSpaceBefore", "null", ["number"], [space]);
    }
    SetParagraphSpaceAfter(space) {
        this.Call("SetParagraphSpaceAfter", "null", ["number"], [space]);
    }
    SetWaterMarkConfig(id, jsonCfg = {}) {
        return this.Call("SetWaterMarkConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    GetWaterMarkReport() {
        return this.Call("GetWaterMarkReport", "object", ["null"], ["null"]);
    }
    GetMenu() {
        return this.Call("GetMenu", "object", ["null"], ["null"]);
    }
    SetBackgroundColor(backColor) {
        this.Call("SetBackgroundColor", "null", ["string"], [backColor]);
    }
    DisplaySeparatorSetWindow(displayMode) {
        this.Call("DisplaySeparatorSetWindow", "null", ["number"], [displayMode]);
    }
    DisplayElementSetWindow(displayMode, setMode, jsonCfg = {}) {
        this.Call("DisplayElementSetWindow", "null", ["number", "number", "object"], [displayMode, setMode, jsonCfg]);
    }
    DisplayCheckBoxSetWindow(displayMode, setMode) {
        this.Call("DisplayCheckBoxSetWindow", "null", ["number", "number"], [displayMode, setMode]);
    }
    DisplayTableBoxSelector(displayMode) {
        this.Call("DisplayTableBoxSelector", "null", ["number"], [displayMode]);
    }
    DisplayPageNumSetWindow(displayMode, setMode) {
        this.Call("DisplayPageNumSetWindow", "null", ["number", "number"], [displayMode, setMode]);
    }
    DisplayFormulaSetWindow(displayMode, setMode) {
        this.Call("DisplayFormulaSetWindow", "null", ["number", "number"], [displayMode, setMode]);
    }
    DisplayImageSetWindow(displayMode, setMode) {
        this.Call("DisplayImageSetWindow", "null", ["number", "number"], [displayMode, setMode]);
    }
    DisplayImageEditWindow(displayMode, setMode) {
        this.Call("DisplayImageEditWindow", "null", ["number", "number"], [displayMode, setMode]);
    }
    DisplayBarCodeSetWindow(displayMode, setMode) {
        this.Call("DisplayBarCodeSetWindow", "null", ["number", "number"], [displayMode, setMode]);
    }
    DisplayMessageBoxWindow(displayMode, jsonCfg) {
        this.Call("DisplayMessageBoxWindow", "null", ["number", "object"], [displayMode, jsonCfg]);
    }
    DisplayPrintSetWindow(displayMode, jsonCfg) {
        this.Call("DisplayPrintSetWindow", "null", ["number", "object"], [displayMode, jsonCfg]);
    }
    DisplayKnowledgeSetWindow(displayMode) {
        this.Call("DisplayKnowledgeSetWindow", "null", ["number"], [displayMode]);
    }
    DisplayTableSetWindow(displayMode) {
        this.Call("DisplayTableSetWindow", "null", ["number"], [displayMode]);
    }
    DisplaySearchReplaceWindow(displayMode) {
        this.Call("DisplaySearchReplaceWindow", "null", ["number"], [displayMode]);
    }
    DisplayFontSetWindow(displayMode) {
        this.Call("DisplayFontSetWindow", "null", ["number"], [displayMode]);
    }
    DisplayParagraphSetWindow(displayMode) {
        this.Call("DisplayParagraphSetWindow", "null", ["number"], [displayMode]);
    }
    SetSelectContentLock(lock, jsonCfg) {
        return this.Call("SetSelectContentLock", "bool", ["bool", "object"], [lock, jsonCfg]);
    }
    GetOutline(jsonCfg) {
        return this.Call("GetOutline", "object", ["object"], [jsonCfg]);
    }
    GetDocSourceReport(docName) {
        return this.Call("GetDocSourceReport", "object", ["string"], [docName]);
    }
    GetEditorsInfo(docName) {
        return this.Call("GetEditorsInfo", "object", ["string"], [docName]);
    }
    GetQualityControlReport() {
        return this.Call("GetQualityControlReport", "object", ["null"], ["null"]);
    }
    GetReviseReport() {
        return this.Call("GetReviseReport", "object", ["null"], ["null"]);
    }
    SelectRevise(jsonCfg) {
        return this.Call("SelectRevise", "bool", ["object"], [jsonCfg]);
    }
    SetReviseConfig(jsonCfg) {
        return this.Call("SetReviseConfig", "bool", ["object"], [jsonCfg]);
    }
    GotoOutline(jsonCfg) {
        return this.Call("GotoOutline", "bool", ["object"], [jsonCfg]);
    }
    SelectAnnotates(jsonCfg) {
        return this.Call("SelectAnnotates", "bool", ["object"], [jsonCfg]);
    }
    Search(searchCfg) {
        return this.Call("Search", "bool", ["object"], [searchCfg]);
    }
    SearchReplace(searchCfg) {
        return this.Call("SearchReplace", "bool", ["object"], [searchCfg]);
    }
    SetPageConfig(jsonCfg) {
        return this.Call("SetPageConfig", "bool", ["object"], [jsonCfg]);
    }
    GetPageProperties() {
        return this.Call("GetPageProperties", "object", ["null"], ["null"]);
    }
    SetHeaderConfig(jsonCfg) {
        return this.Call("SetHeaderConfig", "bool", ["object"], [jsonCfg]);
    }
    SetFooterConfig(jsonCfg) {
        return this.Call("SetFooterConfig", "bool", ["object"], [jsonCfg]);
    }
    GetTimeAxisConfig(id) {
        return this.Call("GetTimeAxisConfig", "object", ["string"], [id]);
    }
    SetTimeAxisConfig(id, jsonCfg) {
        return this.Call("SetTimeAxisConfig", "bool", ["string", "object"], [id, jsonCfg]);
    }
    SetTimeGridConfig(jsonCfg) {
        return this.Call("SetTimeGridConfig", "bool", ["object"], [jsonCfg]);
    }
    SetTimeData(jsonDataArr) {
        return this.Call("SetTimeData", "bool", ["object"], [jsonDataArr]);
    }
    UpdateTimeAxis(id) {
        return this.Call("UpdateTimeAxis", "bool", ["string"], [id]);
    }
    WriteClipboard(e) {
        if (!this.IsEditorFocus() || e.clipboardData == null) {
            return;
        }
        let textExStr = this.GetSelectRangeContent(E_DOC_FORMAT.TextEx, { clipboard: true });
        let textEx = JSON.parse(textExStr);
        var xmlStr = this.GetSelectRangeContent(E_DOC_FORMAT.XML, { clipboard: true });
        {
            var htmlStr = this.CanCopyToOuter() ? this.GetSelectRangeContent(E_DOC_FORMAT.HTML, { clipboard: true }) : "";
            var textStr = this.CanCopyToOuter() ? textEx.text : "";
            shareDataManager.clipboard.xmlStr = xmlStr;
            shareDataManager.clipboard.textExStr = textExStr;
            shareDataManager.clipboard.textStr = textStr;
            shareDataManager.clipboard.htmlStr = htmlStr;
            e.clipboardData.setData("text/plain", textStr);
            e.clipboardData.setData("text/html", htmlStr);
            try {
                e.clipboardData.setData("web text/xml", xmlStr);
            }
            catch (error) {
                console.warn("剪贴板1-不支持navigator.clipboard特殊类型！", error);
            }
            try {
                e.clipboardData.setData("web text/plain", textExStr);
            }
            catch (error) {
                console.warn("剪贴板1-不支持navigator.clipboard特殊类型！", error);
            }
        }
    }
    WriteClipboardV2() {
        let textExStr = this.GetSelectRangeContent(E_DOC_FORMAT.TextEx, { clipboard: true });
        let textEx = JSON.parse(textExStr);
        var textExBlob = new Blob([textExStr], { type: "web text/plain" });
        var xmlStr = this.GetSelectRangeContent(E_DOC_FORMAT.XML, { clipboard: true });
        var xmlBlob = new Blob([xmlStr], { type: "web text/xml" });
        {
            var htmlStr = this.CanCopyToOuter() ? this.GetSelectRangeContent(E_DOC_FORMAT.HTML, { clipboard: true }) : "";
            var htmlBlob = new Blob([htmlStr], { type: "text/html" });
            var textStr = this.CanCopyToOuter() ? textEx.text : "";
            var textBlob = new Blob([textStr], { type: "text/plain" });
            shareDataManager.clipboard.xmlStr = xmlStr;
            shareDataManager.clipboard.textExStr = textExStr;
            shareDataManager.clipboard.textStr = textStr;
            shareDataManager.clipboard.htmlStr = htmlStr;
            try {
                var clipboardData = new ClipboardItem({
                    [`web text/xml`]: xmlBlob,
                    [`web text/plain`]: textExBlob,
                    [`text/html`]: htmlBlob,
                    [`text/plain`]: textBlob,
                });
            }
            catch (error) {
                console.warn("剪贴板2-不支持navigator.clipboard特殊类型！", error);
                var clipboardData = new ClipboardItem({
                    [`web text/xml`]: xmlBlob,
                    [`web text/plain`]: textExBlob,
                });
            }
            navigator.clipboard.write([clipboardData]);
        }
    }
}
__decorate([
    Validator,
    __param(0, SetFontConfigCfgValidator())
], ThinkEditor.prototype, "SetFontConfig", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0, 55)")),
    __param(1, NumValidator("(0, 2]")),
    __param(2, InsertSeparatorCfgValidator())
], ThinkEditor.prototype, "InsertSeparator", null);
__decorate([
    Validator,
    __param(0, StrValidator())
], ThinkEditor.prototype, "SetParagraphConfig", null);
__decorate([
    Validator,
    __param(0, StrValidator())
], ThinkEditor.prototype, "SetElementConfig", null);
__decorate([
    Validator,
    __param(0, NotEmptyStrValidator())
], ThinkEditor.prototype, "SetFontType", null);
__decorate([
    Validator,
    __param(0, NumValidator("(0.1 ,55]"))
], ThinkEditor.prototype, "SetFontSize", null);
__decorate([
    Validator,
    __param(0, NotEmptyStrValidator())
], ThinkEditor.prototype, "SetFontSizeItem", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetFontScript", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetFontItalic", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetFontBold", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetFontUnderline", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetFontStrikethrough", null);
__decorate([
    Validator,
    __param(0, ColorValidator())
], ThinkEditor.prototype, "SetFontColor", null);
__decorate([
    Validator,
    __param(0, ColorValidator())
], ThinkEditor.prototype, "SetFontBackColor", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetFontFerrule", null);
__decorate([
    Validator,
    __param(0, StrValidator()),
    __param(1, StrValidator())
], ThinkEditor.prototype, "SetParagraphContent", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetParagraphListMode", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetParagraphListLevel", null);
__decorate([
    Validator,
    __param(0, StrValidator())
], ThinkEditor.prototype, "SetParagraphListHeaderStr", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetParagraphSpecificIndentFormat", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetParagraphAlignContent", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0,55]"))
], ThinkEditor.prototype, "SetParagraphLeftIndent", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0,55]"))
], ThinkEditor.prototype, "SetParagraphRightIndent", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0,55]"))
], ThinkEditor.prototype, "SetParagraphSpecificIndentCharacterCount", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0, 55]"))
], ThinkEditor.prototype, "SetParagraphLineSpacingValue", null);
__decorate([
    Validator,
    __param(0, IntValidator())
], ThinkEditor.prototype, "SetParagraphLineSpacingRule", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0,55]"))
], ThinkEditor.prototype, "SetParagraphSpaceBefore", null);
__decorate([
    Validator,
    __param(0, NumValidator("[0,55]"))
], ThinkEditor.prototype, "SetParagraphSpaceAfter", null);
__decorate([
    Validator,
    __param(0, StrValidator())
], ThinkEditor.prototype, "SetWaterMarkConfig", null);
