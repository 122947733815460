import { E_DOCS_ORGANIZE_MODE, E_VIEW_MODE, E_DOC_FORMAT, E_DOC_TYPE } from '@/components/editor/ThinkEditor.Defined';
import { ShowSaveFileDialog } from '@/components/editor/ThinkEditor.Utils.js';

let Base64 = require('js-base64').Base64;

export const DemoCaseUnionDocEleIdOpt = {
  data() {
    return {
      unionDocEleIdOptDocName: '文档合并-按Id-设置元素值'
    };
  },
  methods: {
    /**
     * @function DemoCaseUnionDocEleIdOpt
     * @description 合并多文档相关操作。
     * @return {}
     * @example
     */
    async DoDemoCaseUnionDocEleIdOpt() {
      let currentTabsView = this.defaultEditorsTabsView;
      let docName = this.unionDocEleIdOptDocName;

      let thinkEditor = this.GetMyThinkEditor(docName);
      if (thinkEditor === undefined) {
        //文档不存在，则创建
        thinkEditor = await currentTabsView.CreateDocEditor(docName);
        //合并文档
        await this.UnionDocsOpt(thinkEditor, docName);
      }

      //选择显示该文档
      currentTabsView.SelectDocEditor(docName);

      return thinkEditor;
    },
    GetMyThinkEditor(docName) {
      let currentTabsView = this.defaultEditorsTabsView;

      /******************************************************************
       * 结合应用自己的前端框架-取得EditorsInstance
       * *****************************************************************/
      let currentEditorsInstance = currentTabsView.editorsInstance;

      //取得thinkEditor
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      return thinkEditor;
    },
    async UnionDocsEleIdOpt(thinkEditor, docName) {
      thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
      thinkEditor.SelectDoc(docName);
      thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');

      let basePath = '/system/emr/功能/测试/合并文档操作';

      let subDocNames = ['SubDoc1', 'SubDoc2'];
      for (let i = 0; i < subDocNames.length; i++) {
        let subDocName = subDocNames[i];
        var url = basePath + `/${subDocName}.xml`;
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();
        thinkEditor.ParseDocs(docName, subDocName, xml, { organizeMode: E_DOCS_ORGANIZE_MODE.UnionContent, recoverSelection: 0 });

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
    },
    async OnReOpenDocEleIdTest() {
      let docName = this.unionDocEleIdOptDocName;
      /********************************
       * step0: 取得需要操作的thinkEditor
       * ******************************/
      //取得thinkEditor
      let thinkEditor = this.GetMyThinkEditor(docName);

      /********************************
       *[关键点] step1: 重初始化（释放旧的文档资源）
       * ******************************/
      await thinkEditor.InitAsync();
      /********************************
       * step2: 重新创建文档 合并文档
       * ******************************/
      await this.UnionDocsOpt(thinkEditor, docName);
    },
    async OnSetEleContentByEleIdTest() {
      let docName = this.unionDocEleIdOptDocName;
      //取得thinkEditor
      let thinkEditor = this.GetMyThinkEditor(docName);

      /**********************************
       * 不指定subDocName--按Id设置全文元素
       * ***************************************/
      thinkEditor.SetElementsContent(
        [
          {
            id: 'name',
            content: '张三all'
          },
          {
            id: 'age',
            content: '26'
          }
        ],
        {}
      );
    },
    async OnSetEleContentByEleIdForSubDoc2Test() {
      let docName = this.unionDocEleIdOptDocName;
      //取得thinkEditor
      let thinkEditor = this.GetMyThinkEditor(docName);

      /**********************************
       * 不指定subDocName--按源设置全文元素
       * ***************************************/
      thinkEditor.SetElementsContent(
        [
          {
            id: 'name',
            content: '张三2'
          },
          {
            id: 'age',
            content: '22'
          }
        ],
        { subDocName: 'SubDoc2' }
      );
    },
    async OnSaveWholeDocEleIdTest() {
      let docName = this.unionDocEleIdOptDocName;
      //取得thinkEditor
      let thinkEditor = this.GetMyThinkEditor(docName);

      /**********************************
       * 默认或multipleDocs=false 则为将整篇合并文档作为1个文档整体保存
       * ***************************************/
      var dataBuffer = this.thinkEditor.GetDoc(E_DOC_FORMAT.XML, E_DOC_TYPE.Entity, { formatPretty: true });
      ShowSaveFileDialog('WholeDoc.xml', dataBuffer);
    },
    async OnSaveSubDocEleIdTest() {
      let docName = this.unionDocEleIdOptDocName;
      //取得thinkEditor
      let thinkEditor = this.GetMyThinkEditor(docName);

      /**********************************
       * multipleDocs=true 多文档(合并)编辑时，提取为多个子文档
       *  [
              {
                  "docName": "SubDoc1",
                  "xml": "*base64*"
              },
              {
                  "docName": "SubDoc2",
                  "xml": "*base64*"
              }
          ]
       * ***************************************/
      var multipleDocsJsonArrStr = this.thinkEditor.GetDoc(E_DOC_FORMAT.XML, E_DOC_TYPE.Entity, { multipleDocs: true });

      //必要，转化为对象
      let multipleDocs = JSON.parse(multipleDocsJsonArrStr);

      for (let i = 0; i < multipleDocs.length; i++) {
        let subDocItem = multipleDocs[i];
        if (subDocItem.docName === 'SubDoc2') {
          //Base64 解码，得到最终的SubDoc2 Xml 数据
          var subDocXml = Base64.decode(subDocItem.xml);

          //保存SubDoc2
          ShowSaveFileDialog('SubDoc2.xml', subDocXml);
          break;
        }
      }
    },
    OnGetElementPropertiesEleIdTest() {
      let docName = this.unionDocEleIdOptDocName;
      //取得thinkEditor
      let thinkEditor = this.GetMyThinkEditor(docName);

      //let eleContents = thinkEditor.GetElementProperties({ mode: 2, onlyContent: true, subDocName: 'SubDoc2', ids: ['name', 'age'] });
      let eleContents = thinkEditor.GetElementProperties({ mode: 2, onlyContent: true, ids: ['name', 'age'] });

      this.popDialogTitle = 'GetElementProperties';
      this.popDialogText = JSON.stringify(eleContents, null, 2);
      this.popDialogVisible = true;
    }
  }
};
